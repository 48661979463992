<template>
  <div class="global custom-scrollbar">
    <Schedule />
    <div v-if="feed" class="feed">
      <h2>Activity this week</h2>
        <div v-if="feed.marketActivity.length > 0">
          <li @click="showDetails(entry.piece.objectID)" v-for="(entry, index) in feed.marketActivity" :key="index" class="feed-item">
            <span class="info">
              <span><strong>{{entry.user}}</strong> <span class="collect" :class="entry.action">{{entry.action}}</span></span>
              <span class="line-clamp-3"><em>{{this.$store.state.handleTitle(entry.piece.title)}}</em></span>
              <span class="time-ago">{{GetTimeAgo(entry.date)}}</span>
              <!-- <div class="liked-by text-small">
                Liked by
              </div> -->
            </span>
            <div class="thumb">
              <img :src="entry.piece.primaryImageSmall"/>
            </div>
          </li>
        </div>
        <div v-else>
          <p class="text-light">No activity to report</p>
        </div>
    </div>
  </div>
</template>

<script>
import db from '@/main'
import axios from 'axios'
import moment from 'moment'
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
import router from '../router/index.js'
import Schedule from '@/components/Schedule'
export default {
  name: 'Global',
  components: {
    Schedule
  },
  data() {
    return {
      feed: null,
      details: false,
      detailPiece: null,
      weekNumber: null
    }
  },
  methods: {
    GetObjectDetails(id) {
      axios.get('https://collectionapi.metmuseum.org/public/collection/v1/objects/' + id)
      .then((response) => {
          return response.data
      })
    },
    GetTimeAgo(time){
      var timeWrapper = moment(time)
      return timeWrapper.from()
    },
    SortFeed(method) {
      this.sortMethod = method
      switch(method) {
        case 'recent':
          this.feed.sort((x, y) => {
            var item1 = new Date(x.date)
            var item2 = new Date(y.date)
            return item2 - item1
          })
          break
      }
    },
    showDetails(id) {
      router.push('/object?id=' + id)
    }
  },
  mounted() {
    var currentdate = new Date();
    var oneJan = new Date(currentdate.getFullYear(),0,1);
    var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
    var result = Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7);
    this.weekNumber = result
    db.collection("global").doc("feed").get().then((doc) => {
      if(doc.exists) {
        this.feed = doc.data()
      }
    }).then(() => {
      this.feed.marketActivity.sort(function(x, y) {
        var item1 = new Date(x.date)
        var item2 = new Date(y.date)
        return item2 - item1
      })
    })
  }
}
</script>

<style scoped>
.global {
  width: 100%;
  grid-area: 2 / 1 / 4 / 1;
  height: 100%; 
  background-color: black;
  padding: 0px;
  box-sizing: border-box;
  overflow-y: scroll;
  padding: 20px;
}
.feed {
  margin-top: 80px;
}
.feed h2 {
  margin-top: 0px;
  margin-bottom: 20px;
}
.feed-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin-bottom: 20px;
  color: #fff;
  overflow: hidden;
  cursor: pointer;
}
.feed .info {
  line-height: 18px;
  flex-shrink: 20;
}
.feed-item .thumb {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin-left: 20px;
    border-radius: 4px;
}
.thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.time-ago {
  font-size: 12px;
  color: #404040;
  margin-top: 6px;
  display: block;
}
.preview {
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 999;
  position: absolute;
}
.collected {
  color: #dd8aff;
}
.released {
  color: #83fb83;
}
.hearted, .tracked {
  color: #6aacff;
}
.described {
  color: #fff;
}
@media screen and (min-width: 900px) {
  .global {
    grid-area: 1 / 1 / 4 / 1;
    margin: 20px auto 0;
    padding-bottom: 0;
    width: 600px;
    margin: 0 auto;
    padding-top: 40px;
  }
  .feed-item {
    margin-bottom: 40px;
  }
  .feed-item .thumb {
    width: 200px;
    height: 200px;
    overflow: hidden;
    margin-left: 20px;
  }
}
</style>