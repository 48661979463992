<template>
    <div v-if="currentDepts" class="dept-info">
        <h2 class="on-view">Currently on view<br/><span class="text-light">{{currentDepts.name}}</span></h2>
        <img :src="currentDepts.previewImage">
        <div class="copy">
            <div class="description-container" v-if="!loader">
                <p :class="{clamp: !showDescription}" class="description">{{currentDepts.description}} Source: <a :href="currentDepts.url" target="_blank">The Met</a></p>
                <div @click="showDescription = !showDescription" class="button">{{showDescription ? 'Less' : 'Read more'}}</div>
            </div>
            <div v-if="loader" class="loader"><div class="square-rotate-2"></div>Gathering objects...</div>
            <div v-else>
                <Schedule />
            </div>
        </div>
    </div>
</template>

<script>
import Schedule from '@/components/Schedule'
export default {
    name: 'DeptInfo',
    props: {
        loader: Boolean,
        counter: Number,
        schedule: Array,
        currentDepts: Object
    },
    components: {
        Schedule
    },
    data () {
        return {
            showDescription: false,
            countdown: null,
            timer: 0
        }
    },
    mounted() {
        this.timer = setInterval(() => {
            this.countdown = this.$store.state.msToTime(this.$store.state.GetCountdown())
        }, 1000)
    },
    unmounted() {
        clearInterval(this.timer)
    }
}
</script>

<style scoped>
.dept-info {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
}
.dept-info img {
    border-radius: 8px;
}
.dept-info .read-more {
    font-weight: bold;
    cursor: pointer;
    border-bottom: 1px solid #404040;
    width: fit-content;
}
.dept-info .on-view {
    margin-bottom: 20px;
}
.description-container {
    margin-bottom: 40px;
}
.dept-info img {
    width: 100%;
}
.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.done {
    color: #83fb83;
}
.loader {
    display: flex;
    align-items: center;
    margin-top: 16px;
}
.loader .square-rotate-2 {
    margin-right: 20px;
}
.placeholder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
}
.placeholder img {
    margin-bottom: 20px;
}
img.hero-icon {
    width: 200px;
    height: 200px;
}
h2 {
    margin: 0;
}
@media screen and (min-width: 900px){
    .dept-info {
        overflow: hidden;
        width: 900px;
    }
}
</style>