<template>
    <div @click="$emit('close')" class="value-breakdown values">
        <div class="values-inner">
            <p><strong>Metcoin valuation</strong></p>
            <transition-group name="list" tag="ul">
                <li class="line-item" :class="{hideStat: !stat.show}" v-for="stat in scoreObj.stats" :key="stat">
                    <p>{{stat.displayName}}
                    <span class="line"></span>
                    <span v-if="stat.displayName === 'Age bonus'">{{this.$store.state.PrettyPrintNumber(stat.age)}}</span>
                    <span v-if="stat.displayName === 'Renowned artist'">{{'+' + this.$store.state.PrettyPrintNumber(stat.bonus)}}</span>
                    <span v-else-if="stat.displayName !== 'Age bonus'">{{stat.multiplier + 'x'}}</span>
                    </p>
                </li>
            </transition-group>
            <transition-group name="list" tag="ul">
                <li class="line-item" :class="{hideStat: stat.list.length === 0}" v-for="(stat, index) in scoreObj.materials" :key="index">
                    <p class="text-light">{{stat.displayName}}</p>
                    <p v-for="material in stat.list" :key="material">{{material.charAt(0).toUpperCase() + material.slice(1)}}
                    <span class="line"></span>
                    <span>{{stat.multiplier}}x</span>
                    </p>
                </li>
            </transition-group>
            <br/>
            <div class="line-item flex">
                <p><strong>Object value</strong></p>
                <ObjectScore
                @showvaluesbreakdown="showValuesBreakdown = true"
                :score="scoreObj.prettyScore"
                :showScore="true"/>
            </div>
        </div>
    </div>
</template>

<script>
import ObjectScore from '@/components/ObjectScore'
export default {
    name: 'Valuation',
    components: {
        ObjectScore
    },
    data() {
        return {
            showValues: false,
        }
    },
    props: {
        scoreObj: Object,
        show: Boolean
    },
    methods: {
        getKeyByValue(object, value) {
            return Object.keys(object).find(key => object[key] === value);
        },
        getMaterialsList(arr) {
            var list = ''
            for (var i = 0; i < arr.length; i++) {
                if (i < arr.length - 1) {
                    list += arr[i].charAt(0).toUpperCase() + arr[i].slice(1) + ', '
                } else {
                    list += arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
                }
            }
            return list
        }
    },
    mounted() {
        setTimeout(() => {
            this.showValues = true
        }, 1000);
    }
}
</script>

<style scoped>
.value-breakdown {
    z-index: 99;
    grid-area: 1/1/3/1;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    align-self: end;
    position: fixed;
    display: flex;
}
.value-breakdown .values-inner {
    max-height: 100%;
    width: 100%;
    background-color: #080600f5;
    padding: 60px 20px 20px;
}
ul {
    padding: 0;
}
.values .line-item {
    margin: 16px 0;
    list-style: none;
}
.line-item.hideStat {
    display: none;
}
.line-item.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.materials-list {
    color: #636363;
}
.line-item p {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.values p span {
    font-family: 'Roboto Mono';
}
.values .line {
    width: 50px;
    height: 1px;
    border-bottom: 1px dashed #404040;
    flex-grow: 2;
    margin: 0 6px;
}
.value-breakdown .values .close-button {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
}
.values .object-score {
    margin-top: 0;
}
.line-item-header {
    color: #404040;
    margin-bottom: 4px;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}

@media screen and (min-width: 900px) {
    .value-breakdown {
        background-color: rgba(0, 0, 0, 0.973);
    }
    .value-breakdown .values-inner {
        margin: auto;
        width: 300px;
        padding: 20px;
        border-radius: 10px;
        border: 1px solid #111;
    }
}
</style>