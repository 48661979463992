<template>
  <div class="register">
      <h2>Create an account</h2>
      <br/>
      <form @submit.prevent="Register">
        <input type="text" placeholder="Username" v-model="username" />
        <p class="text-light">Others will see this name in the global feed.</p>
        <input type="text" placeholder="Email" v-model="email" />
        <p class="text-light">We do not share or sell your email address.</p>
        <input type="password" placeholder="Password" v-model="password" />
        <p class="text-light">At least 8 characters.</p>
        <input type="submit" value="Create account">
        <p class="text-danger">{{feedback}}</p>
        <p>Have an account? <router-link to="/login">Log in here</router-link></p>
      </form>
  </div>
</template>

<script>
import slugify from 'slugify'
import firebase from 'firebase/compat/app'
import db from '@/main'
import {ref} from 'vue'
export default {
  setup() {
    const email = ref('')
    const password = ref('')
    const username = ref('')
    let userSlug = ref('')
    let feedback = ref('')

    const Register = () => {
      if(email.value !== '' && password.value !== '' && username.value !== '') {
        userSlug.value = slugify(username.value, {
          replacement: '-',
          remove: /[$*_+.()'"!\-:@]/g,
          lower: true
        })
        let ref = db.collection('users').doc(userSlug.value)
        ref.get().then(doc => {
          if(doc.exists) {
            feedback.value = 'Username already taken.'
          } else {
            firebase.auth().createUserWithEmailAndPassword(email.value, password.value)
            .then(user => {
              console.log('New user created: ', user)
              let ref = db.collection('users').doc(userSlug.value)
              ref.set({
                username: username.value,
                userSlug: userSlug.value,
                user_id: user.user.uid,
                collected: [],
                seen: 0,
                examined: 0,
                preferences: {
                  interval: 10,
                  hideFragments: false,
                  algo: true
                }
              })
            })
            .catch(err => {
              if (err.code == 'auth/invalid-email') {
                feedback.value = 'Please enter a valid email address.'
              } else if (err.code == 'auth/email-already-in-use') {
                feedback.value = 'Email address already in use.'
              } else if (err.code == 'auth/weak-password') {
                feedback.value = 'Password must be at least 8 characters.'
              } else if (err.code == 'auth/wrong-password') {
                feedback.value = 'Incorrect password or email address.'
              } else {
                feedback.value = err.code
              }
            })
          }
        })
      } else {
        feedback.value = 'Please fill all fields'
      }
    }

    return {
      Register, email, password, feedback, username, userSlug
    }
  }
}
</script>

<style scoped>
.register {
  background-color: black;
  padding: 20px;
  grid-area: 1 / 1 / 4 / 1;
  width: 300px;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  margin: 0 auto;
  text-align: center;
}
.register form {
  display: flex;
  flex-direction: column;
  width: 300px;
}
input {
  margin-bottom: 4px;
}
p {
  margin-top: 0px;
  margin-bottom: 20px;
}
</style>