<template>
  <div class="actions">
        <div v-if="showSwapModal" @click.prevent="showSwapModal = false" class="overlay"></div>
        <div v-if="showSwapModal" class="quick-release custom-scrollbar">
            <span v-if="!choosingSwap">
                <div class="header">
                    <p>Choose an object to swap for <i>{{piece.title}}</i> by {{piece.artistDisplayName == '' ? 'Unknown' : piece.artistDisplayName}}.</p>
                </div>
                <div @click="choosingSwap = true; objectToSwap = piece" class="row-item cursor-pointer" v-for="(piece, index) in this.$store.state.userData.collected" :key="index">
                    <div class="info">
                        <p class="line-clamp-1">{{piece.artistDisplayName == '' ? 'Unknown' : piece.artistDisplayName}}</p>
                        <p class="title line-clamp-1">{{piece.title}}</p>
                    </div>
                    <div class="image">
                        <img :src="piece.primaryImageSmall">
                    </div>
                </div>
            </span>
            <div v-else class="swap-confirmation">
                <div class="header">
                    <p>Are you sure you want to make this change?</p>
                </div>
                <div class="row-item cursor-pointer">
                    <div class="info">
                        <p class="released">Release</p>
                        <p class="line-clamp-1">{{objectToSwap.artistDisplayName == '' ? 'Unknown' : objectToSwap.artistDisplayName}}</p>
                        <p class="title line-clamp-1">{{objectToSwap.title}}</p>
                    </div>
                    <div class="image">
                        <img :src="objectToSwap.primaryImageSmall">
                    </div>
                </div>
                <div class="row-item cursor-pointer">
                    <div class="info">
                        <p class="collected">Collect</p>
                        <p class="line-clamp-1">{{piece.artistDisplayName == '' ? 'Unknown' : piece.artistDisplayName}}</p>
                        <p class="title line-clamp-1">{{piece.title}}</p>
                    </div>
                    <div class="image">
                        <img :src="piece.primaryImageSmall">
                    </div>
                </div>
                <div class="button-group">
                    <div @click="choosingSwap = false" class="button">
                        Cancel
                    </div>
                    <div @click="swapObjects(objectToSwap, piece)" class="button">
                        Confirm
                    </div>
                </div>
            </div>
        </div>
        <div class="button-group">
            <div @click.prevent="goBack" class="button">
                <span class="material-icons">keyboard_backspace</span>
                Back
            </div>
            <TransitionGroup v-if="this.$store.state.loggedIn" name="button" tag="div">
                <div v-if="IsCollected" @click.prevent="$emit('uncollect-piece', piece)" class="button">
                    <span class="material-icons">remove_circle_outline</span>Release
                </div>
                <div v-else-if="!isCollected && this.$store.state.userData.collected.length >= 10" @click.prevent="showSwapModal = !showSwapModal; choosingSwap = false" class="button">
                    <span class="material-icons">autorenew</span>Swap
                </div>
                <div v-else @click.prevent="$emit('collect-piece', piece)" class="button"> 
                    <span class="material-icons">add_circle_outline</span>Collect
                </div>
            </TransitionGroup>
            <router-link v-else to="/collection" class="button"> 
                <span class="material-icons">add_circle_outline</span>Collect
            </router-link>
            <a @click.stop :href="piece.objectURL" target="_blank" class="button no-border">
                <span class="material-icons">open_in_new</span>The Met
            </a>
        </div>
    </div>
</template>

<script>
import router from '../router/index.js'
export default {
    name: 'Actions',
    props: {
        piece: Object,
        owned: String
    },
    data() {
        return {
            isCollected: false,
            currentURL: null,
            showSwapModal: false,
            choosingSwap: false,
            objectToSwap: null,
            swapping: false
        }
    },
    methods: {
        goBack() {
            router.go(-1)
        },
        swapObjects(released, collected) {
            this.swapping = true
            this.$emit('uncollect-piece', released)
            setTimeout(() => {
                this.$emit('collect-piece', collected)
                this.choosingSwap = false
                this.showSwapModal = false
                this.swapping = false
            }, 1000);
        }
    },
    computed: {
        IsCollected: function() {
            var collected = false
            for(var i = 0; i < this.$store.state.userData.collected.length; i++) {
                if (this.$store.state.userData.collected[i].objectID === this.piece.objectID) {
                    collected = true
                }
            }
            return collected
        }
    },
    mounted() {
        this.currentURL = window.location.pathname
    }
}
</script>

<style scoped>
.actions {
    width: 100%;
    box-sizing: border-box;
    grid-area: 2 / 1 / 2 / 1;
    background-color: black;
    padding: 8px 0;
    z-index: 99;
    overflow: hidden;
    height: 60px;
}
.collected {
  color: #dd8aff;
}
.released {
  color: #83fb83;
}
.actions .button-group {
    grid-gap: 0;
    height: 100%;
}
.actions .button-group .button {
    border-color: transparent;
    border-radius: 0px;
    height: 100%;
}
.text-only-button {
    padding: 10px 6px;
    text-align: center;
    flex-grow: 1;
    font-family: 'Roboto Mono', monospace;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    font-size: 11px;
    font-weight: 700;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.text-only-button p {
    margin: 0;
}
.text-only-button .material-icons {
    margin-right: 10px;
    font-size: 16px;
}

.button-move, .button-enter-active {
  transition: all 0.6s cubic-bezier(.32,.69,.44,1);
}
.button-move, .button-leave-active {
  transition: all 0.3s cubic-bezier(.32,.69,.44,1);
  position: absolute;
  display: none;
}
.button-enter-from, .button-leave-to {
  transform: translateY(60px);
  opacity: 0;
}
.button span {
    display: flex;
    align-items: center;
}
.overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: -1;
}
.quick-release {
    position: absolute;
    bottom: 60px;
    width: 100%;
    height: 520px;
    display: flex;
    flex-direction: column;
    background-color: black;
    padding: 20px;
    padding-top: 0px;
    overflow-y: scroll;
}
.quick-release .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 20px 0;
}
.quick-release .row-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
}
.row-item .image {
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin-left: 10px;
    border-radius: 4px;
}
.row-item img {
    width: 100%;
  height: 100%;
  object-fit: cover;
}
.row-item .info {
    flex-shrink: 20;
}
.row-item .info p {
    margin: 0;
}
.swap-confirmation .button-group {
    height: 60px;
}
@media screen and (min-width: 900px) {
  .actions {
    bottom: 0;
    width: 900px;
    margin: 0 auto;
  }
  .quick-release {
    width: 900px;
    border: 1px solid white;
    border-radius: 6px;
  }
  .button-group {
    margin: 0 auto;
  }
}
</style>