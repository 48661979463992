<template>
    <div class="collection custom-scrollbar">
        <div v-if="this.$store.state.loggedIn" class="collection-container" :class="{overflowhidden: showDetails}">
            <div class="collection-info">
                <h2>Collection</h2>
                <ObjectScore :score="GetCollectionScore()" :showScore="true" @click="AnalyzeCollection(this.$store.state.userData.collected)"/>
            </div>
            <div v-if="this.$store.state.userData.collected.length > 0" class="objects" :class="{firstobject: this.$store.state.userData.collected.length === 1}">
                <div class="collected-objects">
                    <router-link :to="'/object?id=' + piece.objectID" class="collection-card cursor-pointer"
                    v-for="(piece, index) in this.$store.state.userData.collected"
                    :key="index">
                        <div class="image">
                            <img :src="piece.primaryImageSmall">
                        </div>
                        <div class="info">
                            <p class="line-clamp-1">{{piece.artistDisplayName == '' ? 'Unknown' : piece.artistDisplayName}}</p>
                            <p class="title line-clamp-1">{{piece.title}}</p>
                        </div>
                    </router-link>
                </div>
                <div class="collection-info">
                    <h2>Likes</h2>
                </div>
                <div class="liked-objects">
                    <div class="objects">
                        <router-link :to="'/object?id=' + piece.objectID" v-for="(piece, index) in this.$store.state.userData.liked" :key="index" class="liked-object">
                            <img :src="piece.primaryImageSmall">
                        </router-link>
                    </div>
                </div>
            </div>
            <div v-else class="empty-collection">
                <p>Your collection is empty.</p>
            </div>
        </div>
        <div v-else class="onboard-card">
            <img src="@/assets/collection-cta-header.png">
            <div class="onboard-copy">
                <p class="onboard-header">Collect art.</p>
                <p class="onboard-body">Become a collector with a free Metitate.art account. Claim prized objects for your personal collection and participate in the global arts and antiquities market.</p>
            </div>
            <div class="button-group">
                <router-link to="/"><div class="cta-button cta-button-secondary">Maybe later</div></router-link>
                <router-link to="/register"><div class="cta-button">Create an account</div></router-link>
            </div>
            <router-link to="/login">Already registered? Log in</router-link>
        </div>
    </div>
</template>

<script>
//import axios from 'axios'
// import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import ObjectScore from '@/components/ObjectScore'
// import db from '@/main'
const { GoogleGenerativeAI } = require("@google/generative-ai")
export default {
    name: 'Collection',
    props: {
    },
    components: {
        ObjectScore
    },
    data() {
        return {
            showDetails: false,
            currentPiece: null,
            owner: null,
            collectionTotalValue: 0,
            collection: [],
            showStats: false,
            activeTab: 'collection'
        }
    },
    computed: {
        collectionCount: function() {
            return this.$store.state.userData.collected.length
        }
    },
    methods: {
        GetCollectionScore() {
            var collectionScore = this.$store.state.GetCollectionScore(this.$store.state.userData.collected)
            return this.$store.state.PrettyPrintNumber(collectionScore)
        },
        hideDetailsOnSwipe() {
            if(this.showDetails) {
                this.showDetails = false
            }
        },
        async AnalyzeCollection(collection) {
            const prompt = 'The following text is a JSON object that contains a collection of paintings, sculptures, artifacts, and other arts and crafts from the Metropolitan Museum of Art. Read through the collection and tell me a story about it. The JSON object is: ' + JSON.stringify(collection)

            const genAI = new GoogleGenerativeAI(process.env.VUE_APP_GEMINI_API_KEY);
            // For text-only input, use the gemini-pro model
            var model
            var result
            model = genAI.getGenerativeModel({ model: "gemini-pro"});
            result = await model.generateContent(prompt);
            const response = await result.response;
            var text = response.text()
            if (text) {
                try {
                    console.log(text)
                } catch (e) {
                    return console.log(e, text)
                }
            }
        },
    },
    mounted() {
    }
}
</script>

<style scoped>
.collection {
    background-color: black;
    grid-area: 2 / 1 / 4 / 1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    overflow-y: scroll;
}
.collection-container {
    grid-area: 1 / 1 / 2 / 1;
    padding: 20px;
    margin-bottom: 20px;
    overflow-y: visible;
}
.collection-container.overflowhidden {
    overflow: hidden;
}
.collected-objects, .liked-objects {
    display: grid;
    grid-gap: 14px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    overflow-y: visible;
    margin-top: 20px;
    margin-bottom: 40px;
}
.objects.firstobject {
    width: 40%;
}
.empty-collection {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}
.collection-info {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
}
.collection-info .tab-bar {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;
}
.tab-bar .tab {
    margin-right: 20px;
    opacity: 0.5;
    cursor: pointer;
}
.tab.active {
    opacity: 1;
}
.collection-container .collection-card {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
a.collection-card, a.liked-object {
    border-bottom: none;
}
.collection-card .image {
    overflow: hidden;
    margin-bottom: 10px;
}
.collection-card img {
    width: 100%;
    height: 100%;
    transition: all .5s ease-out;
    transform: scale(1.05)
}
.collection-card img:hover {
    transform: scale(1.1)
}
.collection-card .info {
    font-size: 12px;
    overflow: hidden;
}
.collection-card .info p {
    margin: 2px 0;
}
.collection-card .info .title {
    font-style: italic;
}
.liked-objects .objects {
    display: grid;
    grid-gap: 14px;
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
    align-items: baseline;
    overflow-y: visible;
}
.liked-objects .liked-object img {
    width: 100%;
}
.stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    background-color: #111;
    padding: 20px 30px;
    border-radius: 4px;
    margin-bottom: 40px;
}
.stats .stat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.stats p {
    margin-bottom: 0px;
    margin-top: 6px;
}
@media screen and (min-width: 900px) {
    .collection {
        grid-area: 1 / 1 / 4 / 1;
    }
    .collection-container {
        width: 1000px;
        margin: 20px auto 0;
    }
    .collected-objects {
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    }
    .liked-objects .objects {
        grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    }
    .stats {
        max-width: 340px;
    }
    .collection-info {
        flex-direction: row;
        align-items: center;
    }
}
</style>