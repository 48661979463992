<template>
    <div class="user-menu">
        <DownloadDatabase v-if="showDownloadUI" />
        <h2>Settings</h2>
            <div @click="toggleAlgo()" class="settings-row">
                <div class="label">
                    <div class="name">Curate feed</div>
                    <div class="description">Show only the most interesting objects from The Met based on factors such as age, materials, artist, and more.</div>
                </div>
                <div class="action">
                    <div class="toggle" :class="{inactive: !this.$store.state.userData.preferences.algo}">
                        <div v-if="this.$store.state.userData.preferences.algo" class="toggle-inner toggle-on"></div>
                        <div v-else class="toggle-inner toggle-off"></div>
                    </div>
                </div>
            </div>
            <div v-if="this.$store.state.userData.preferences.playlist.active !== undefined" class="settings-row" style="display: block;">
                <div class="label">
                    <div class="name">Personal Playlist</div>
                    <div style="max-width: 250px;" class="description">
                        <span>Choose which departments are elligible for your weekly playlist.
                            <div style="cursor: pointer; margin-top: 10px;" @click="showDeptList = !showDeptList">
                                <span v-if="!showDeptList"><strong>Edit selections</strong></span>
                            </div>
                        </span>
                    </div>
                    <div v-if="showDeptList" class="content">
                        <ul>
                            <li @click="toggleSelectAll()" class="select-all" style="margin-bottom: 20px;">
                                <span v-if="this.playlist.length === this.deptList.length" class="material-icons">check_box</span>
                                <span v-else class="material-icons">check_box_outline_blank</span>
                                <strong>Select all</strong>
                            </li>
                            <li v-for="dept in this.deptList" :key="dept" @click="toggleDept(dept)">
                                <span v-if="this.playlist.indexOf(dept) > -1" class="material-icons">check_box</span>
                                <span v-else class="material-icons">check_box_outline_blank</span>
                                {{ dept }}
                            </li>
                        </ul>
                    </div>
                    <div v-if="this.playlist.length < 7 && showDeptList" style="margin-bottom: 20px;">Please select at least 7 departments.</div>
                    <div v-if="showDeptList" class="button-group">
                        <div @click="showDeptList = false; PopulatePlaylist()" class="button">Cancel</div>
                        <div @click="UpdatePlaylist()" class="button button-solid" :class="{disabled: this.playlist.length < 7}">Apply changes</div>
                    </div>
                </div>
                <div @click="togglePlaylist()" class="action" style="position: absolute; top: 28px; right: 10px;">
                    <div class="toggle" :class="{inactive: !this.$store.state.userData.preferences.playlist.active}">
                        <div v-if="this.$store.state.userData.preferences.playlist.active" class="toggle-inner toggle-on"></div>
                        <div v-else class="toggle-inner toggle-off"></div>
                    </div>
                </div>
            </div>
            <div class="settings-row">
                <div class="label">
                    <div class="name">Tutorial</div>
                </div>
                <div class="action">
                    <div v-if="resetting" class="button" :style="{backgroundColor: 'green'}">Success</div>
                    <div v-else class="button" @click="resetTutorial">Reset</div>
                </div>
            </div>
            <div class="settings-row">
                <div class="label">
                    <div class="name">
                        Logged in as {{this.$store.state.userData.username}}
                    </div>
                </div>
                <div class="action">
                    <a class="button" @click="Logout" to="/"><span class="material-icons">logout</span>Log out</a>
                </div>
            </div>
            <div v-if="this.$store.state.userData.username === 'brainnews'" class="settings-row">
                <div class="label">
                    <div class="name">
                        Copy database
                    </div>
                </div>
                <div class="action">
                    <a class="button" @click="showDownloadUI = true"><span class="material-icons">download</span>Begin...</a>
                </div>
            </div>
            
        <!-- <div class="settings-row">
            <div class="label">Interval</div>
            <div class="action multi-item">
                <span>5</span>
                <span>10</span>
                <span>15</span>
            </div>
        </div> -->
    </div>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import db from '@/main'
import { getAuth, sendPasswordResetEmail, deleteUser } from "firebase/auth"
import DownloadDatabase from '@/components/DownloadDatabase.vue'
export default {
    name: 'UserPreferences',
    components: {
        DownloadDatabase
    },
    methods: {
        Logout() {
            firebase.auth().signOut()
            this.$store.commit('updateStatus', {
                type: 'loggedOut',
                message: '',
                ready: true
            })
            this.$router.push('/')
        },
        SendPasswordResetLink() {
            const auth = getAuth()
            const email = auth.currentUser.email
            sendPasswordResetEmail(auth, email)
            .then(() => {
                console.log('email sent')
            })
            .catch((error) => {
                console.log(error)
            });
        },
        DeleteAccount() {
            const auth = getAuth();
            const user = auth.currentUser;
            deleteUser(user).then(() => {
                db.collection('users').doc(this.$store.state.userData.userSlug).delete()
                .then(() => {
                    console.log('user deleted')
                    this.$router.go()
                })
            }).catch((error) => {
                console.log(error.code)
                if(error.code === 'auth/requires-recent-login') {
                    this.requireLogin = true
                }
            });
        },
        DeleteData() {
            db.collection('users').doc(this.$store.state.userData.userSlug).update({
                collected: firebase.firestore.FieldValue.delete()
            })
        },
        toggleAlgo() {
            //If algo is on, turn it off
            if (this.$store.state.userData.preferences.algo) {
                this.$store.commit('toggleAlgo', {
                    algo: false
                })
            } else {
                this.$store.commit('toggleAlgo', {
                    algo: true
                })
            }
            
            db.collection('users').doc(this.$store.state.userData.userSlug).update({
                "preferences.algo": this.$store.state.userData.preferences.algo
            })
        },
        togglePlaylist() {
            if (this.$store.state.userData.preferences.playlist.active) {
                this.$store.commit('togglePlaylist', {
                    active: false
                })
                db.collection("global").doc("schedule").get().then((doc) => {
                    this.$store.commit('getSchedule', {
                        schedule: doc.data().collections
                    })
                })
            } else {
                this.$store.commit('togglePlaylist', {
                    active: true
                })
                db.collection("users").doc(this.$store.state.userData.userSlug).get().then((doc) => {
                    this.$store.commit('getSchedule', {
                        schedule: doc.data().preferences.playlist.schedule.collections
                    })
                })
            }
            
            db.collection('users').doc(this.$store.state.userData.username).update({
                "preferences.playlist.active": this.$store.state.userData.preferences.playlist.active
            })

            this.$store.commit('updateStatus', {
                type: 'resuming',
                mod: 'playlist change',
                message: '',
                ready: true
            })
            console.log(this.$store.state.status)
        },
        resetTutorial() {
            localStorage.tutorialComplete = 'false'
            this.resetting = true
            setTimeout(() => this.resetting = false, 1000)
        },
        UpdatePlaylist() {
            db.collection('users').doc(this.$store.state.userData.userSlug).update({
                "preferences.playlist.list": this.playlist
            }).then(()=> {
                this.$store.commit('updatePlaylist', {
                    list: this.playlist
                })
                this.showDeptList = false
            })
        },
        PopulatePlaylist(){
            this.playlist = []
            for(var i = 0; i < this.$store.state.userData.preferences.playlist.list.length; i++) {
                this.playlist.push(this.$store.state.userData.preferences.playlist.list[i])
            }
        },
        PopulateDepts(){
            for(var i = 0; i < this.$store.state.collectionAreas.length; i++) {
                this.deptList.push(this.$store.state.collectionAreas[i].name)
            }
        },
        CheckDept(dept){
            if (this.playlist.indexOf(dept) > -1) {
                return true
            } else {
                return false
            }
        },
        toggleDept(dept){
            if (this.playlist.indexOf(dept) > -1) {
                var index = this.playlist.indexOf(dept)
                this.playlist.splice(index, 1)
            } else {
                this.playlist.push(dept)
            }
        },
        toggleSelectAll(){
            if (this.playlist.length === this.deptList.length) {
                this.playlist = []
            } else {
                this.playlist = this.deptList
            }
        },
        ShuffleCollections(playlist){
            var departments = playlist
            let galleries = []
            for (var i = 0; i < 7; i++) {
                var randomIndex = Math.floor(Math.random()*departments.length)
                let gallery = departments.splice(randomIndex, 1)
                galleries.push(gallery[0])
            }
            return galleries
        },
        createPlaylistPref(list, dateCreated, collections) {
            this.$store.commit('createPlaylistPref', {
                active: false,
                list: list,
                dateCreated: dateCreated,
                collections: collections
            })
        }
    },
    data () {
        return {
            requireLogin: false,
            resetting: false,
            showDeptList: false,
            playlist: [],
            deptList: [],
            deptsArray: this.$store.state.collectionAreas,
            starterList: [],
            showDownloadUI: false
        }
    },
    beforeMount() {
        if (this.$store.state.userData.preferences.playlist.list === null) {
            console.log('no playlist pref for ', this.$store.state.userData.userSlug)
            const currentDate = new Date()
            let depts = []
            const allDepts = ['American Decorative Arts', 'Ancient Near Eastern Art', 'Arms and Armor', 'Arts of Africa, Oceania, and the Americas', 'Asian Art', 'The Cloisters', 'The Costume Institute', 'Drawings and Prints', 'Egyptian Art', 'European Paintings', 'European Sculpture and Decorative Arts', 'Greek and Roman Art', 'Islamic Art', 'The Robert Lehman Collection', 'The Libraries', 'Medieval Art', 'Musical Instruments', 'Photographs']

            for(var i = 0; i < this.deptsArray.length; i++) {
                depts.push(this.deptsArray[i].name)
            }
            this.starterList = this.ShuffleCollections(depts)
            db.collection('users').doc(this.$store.state.userData.userSlug).update({
                "preferences.playlist.active": false,
                "preferences.playlist.list": allDepts,
                "preferences.playlist.schedule.dateCreated": currentDate,
                "preferences.playlist.schedule.collections": this.starterList
            })

            this.createPlaylistPref(allDepts, currentDate, this.starterList)
        }
    },
    mounted() {
        if (this.$store.state.userData.preferences.playlist.list) {
            this.PopulatePlaylist()
        }
        
        this.PopulateDepts()
    }
}
</script>

<style scoped>
h2 {
    margin-bottom: 20px;
}
.settings-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.1s linear;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #303030;
    border-radius: 6px;
    position: relative;
}
.settings-row:first-child {
    border-top: none;
}
.settings-row p {
    margin: 3px 0;
    flex-shrink: 8;
}
.action {
    cursor: pointer;
    display: flex;
}
.action a {
    display: flex;
    align-items: center;
}
.action a .material-icons {
    font-size: 14px;
    margin-right: 8px;
}
.action .toggle {
    width: 40px;
    height: 20px;
    background-color: #6aacff;
    border-radius: 32px;
    display: flex;
    align-items: center;
    position: relative;
}
.action .toggle.inactive {
    opacity: 0.5;
    background: #858585;
}
.toggle-inner {
    width: 20px;
    height: 20px;
    border-radius: 40px;
    position: absolute;
    background-color: #fff;
    border: 2px solid #fff;
}
.toggle-inner.toggle-on {
    right: 0;
}
.toggle-inner.toggle-off {
    left: 0;
}
.action.multi-item {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.action.multi-item span {
    margin-left: 30px;
}
.settings-row.solid {
    background-color: #101010;
    color: #fff;
}
.settings-container a {
    width: fit-content;
    color: #1b1b1b;
    border-bottom-color: #1b1b1b;
}
.settings .section-header {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-top: 1px solid #404040;
}
.settings-row .content ul {
    padding-left: 0;
}
.settings-row .content li {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.content li span.material-icons {
    margin-right: 10px;
}
.label {
}
.label .name {
    font-weight: bold;
}
.label .description {
    opacity: 0.75;
    line-height: 20px;
}
.label .button-group {
    width: 100%;
}
</style>