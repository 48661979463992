<template>
    <div class="navbar" @click="checkPath()">
            <!-- TICKERS -->
            <div class="left">
                <div class="timer nav-link" :class="{tooltipHighlight: this.$store.state.obStep === 1}" v-if="currentURL === '/' && this.$store.state.currentDepts">
                    <Tooltip v-if="showTutorial" @close="showTutorial = false" @dismiss="showTutorial = false" />
                    <div class="nav-button">
                        <div class="progress-container">
                            <div class="progress" :style="{left: progress + 'px'}"></div>
                        </div>
                    </div>
                </div>

                <router-link class="nav-link" :class="{tooltipHighlight: this.$store.state.obStep === 1}" v-else-if="currentURL === '/object' && this.$store.state.currentDepts" to="/">
                    <Tooltip v-if="showTutorial" @close="showTutorial = false" @dismiss="showTutorial = false" />
                    <div class="nav-button">
                        <div class="progress-container">
                            <div class="progress" :style="{left: progress + 'px'}"></div>
                        </div>
                    </div>
                </router-link>

                <router-link class="nav-link" :class="{tooltipHighlight: this.$store.state.obStep === 1}" v-else-if="this.$store.state.currentDepts" to="/" @click="this.$store.commit('updateStatus', {type: 'resuming', mod: 'playlist change', message: 'Picking up where you left off...', ready: true})">
                    <Tooltip v-if="showTutorial" @close="showTutorial = false" @dismiss="showTutorial = false" />
                    <div class="nav-button">
                        <div class="progress-container">
                            <div class="progress" :style="{left: progress + 'px'}"></div>
                            <img v-if="this.$store.state.loggedIn && $route.path !== '/'" :src="this.$store.state.userData.lastSeenURL">
                        </div>
                    </div>
                </router-link>
                <!-- HOME -->
                <a class="nav-link" v-else href="https://www.metitate.art">
                    <Tooltip v-if="showTutorial" @close="showTutorial = false" @dismiss="showTutorial = false" />
                    <div class="nav-button">
                        <span class="material-icons">home</span>
                        <transition name="fade"><p  v-if="showText">Home</p></transition>
                    </div>
                </a>
            </div>

            <div class="right">
                <router-link class="nav-link" :class="{tooltipHighlight: this.$store.state.obStep === 2}" to="/global" @click="this.$store.commit('updateStatus', {type: 'paused', message: '', ready: true})">
                    <div class="nav-button">
                        <span class="material-icons">date_range</span>
                        <transition name="fade"><p v-if="showText">Schedule</p></transition>
                    </div>
                </router-link>
                
                <router-link class="nav-link" :class="{tooltipHighlight: this.$store.state.obStep === 3}" to="/collection" @click="this.$store.commit('updateStatus', {type: 'paused', message: '', ready: true})">
                    <div class="nav-button">
                        <span class="material-icons">work</span>
                        <transition name="fade"><p v-if="showText">Collection</p></transition>
                    </div>
                </router-link>

                <!-- <router-link v-if="this.$store.state.loggedIn && this.$store.state.userData.username === 'brainnews'" class="nav-link" to="/explore" @click="this.$store.commit('updateStatus', {type: 'paused', message: '', ready: true})">
                    <div class="nav-button">
                        <span class="material-icons">account_balance</span>
                        <transition name="fade"><p v-if="showText">Story</p></transition>
                    </div>
                </router-link> -->

                <router-link v-if="this.$store.state.loggedIn" class="nav-link" to="/settings" @click="this.$store.commit('updateStatus', {type: 'paused', message: '', ready: true})">
                    <div class="nav-button">
                        <span class="material-icons">tune</span>
                        <transition name="fade"><p v-if="showText">Settings</p></transition>
                    </div>
                </router-link>
                <router-link v-else class="nav-link" to="/login" @click="this.$store.commit('updateStatus', {type: 'paused', message: '', ready: true})">
                    <div class="nav-button">
                        <span class="material-icons">login</span>
                        <transition name="fade"><p v-if="showText">Log in</p></transition>
                    </div>
                </router-link>
            </div>
    </div>
</template>

<script>
import db from '@/main'
import Tooltip from '@/components/Tooltip'
export default {
    name: 'Navbar',
    components: {
        Tooltip
    },
    props: {
        progress: Number,
    },
    data() {
        return {
            currentURL: null,
            showIcons: false,
            showText: true,
            algo: null,
            showTutorial: true
        }
    },
    methods: {
        checkPath() {
            this.currentURL = window.location.pathname
        },
        changeMode() {
            if(!this.$store.state.detailsOpen) {
                this.$emit('mode-switch')
                this.showMode = true
                if (this.modeIndex === this.$store.state.modes.length - 1) {
                    this.modeIndex = 0
                } else {
                    this.modeIndex++
                }
                this.$store.commit('changeMode', {
                    mode: this.$store.state.modes[this.modeIndex],
                })
                console.log(this.$store.state.mode)
                setTimeout(() => {
                    this.showMode = false
                }, 1000)
            }
        },
        toggleAlgo() {
            this.algo = !this.algo
            this.$store.commit('toggleAlgo', {
                algo: this.algo
            })
            db.collection('users').doc(this.$store.state.userData.username).update({
                "preferences.algo": this.algo
            })
        }
    },
    mounted() {
        this.currentURL = window.location.pathname
        if (localStorage.tutorialComplete === 'true') {
            this.showTutorial = false
        } else {
            localStorage.tutorialComplete = 'false'
            this.showTutorial = true
        }
        //localStorage.removeItem('tutorialComplete')
        //this.algo = this.$store.state.userData.preferences.algo
    },
    unmounted() {
    }
}
</script>

<style scoped>
a {
    border-bottom: none;
}
.navbar {
    grid-area: 1 / 1 / 1 / 1;
    background-color: black;
    z-index: 100;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 10px;
}
.navbar.border {
    border-bottom: 1px solid #121212;
}
.navbar .inner {
    display: flex;
    justify-content: space-evenly;
    box-sizing: border-box;
    align-items: center;
}
.navbar .left, .navbar .right {
    display: flex;
    align-items: center;
}
.nav-link.tooltipHighlight {
    border-radius: 14px;
    background-color: #3e4146;
}
.progress-container {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #fff;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.progress-container img {
    width: 100%;
}
.progress-container.display-off {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #fff;
    overflow: hidden;
    position: relative;
    color: #222;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress-container.display-off .material-icons {
    margin-bottom: 2px;
    margin-left: 2px;
}
.progress {
    background-color: #e4002b;
    width: 40px;
    position: absolute;
    height: 40px;
    border-radius: 100%;
    transition: all 0.3s cubic-bezier(.32,.69,.44,1);
}
.navbar a, .navbar .timer {
    transition: margin-left 0.6s cubic-bezier(.32,.69,.44,1);
    width: 60px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.navbar .timer {
    opacity: 1;
}
.navbar a:hover {
    opacity: 1;
}
.navbar a.router-link-active {
    opacity: 1;
}
.nav-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    flex-direction: column;
}
.nav-button p {
    display: none;
}
.algo-button {
    font-size: 11px;
    text-transform: uppercase;
    font-family: 'Roboto Mono';
    letter-spacing: .4px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.algo-button.disabled {
    opacity: 0.6;
}
.algo-button.disabled .material-icons {
    color: inherit;
}
.algo-button .material-icons {
    margin-right: 4px;
    color: greenyellow;
}
.collection-counter {
    font-size: 7px;
    color: black;
    font-family: 'Roboto Mono';
    position: absolute;
    text-align: center;
    margin-top: 3px;
}
.inbox-notif {
    width: 10px;
    height: 10px;
    position: absolute;
    background-color: #e4002b;
    border-radius: 100%;
    top: 0;
    right: -4px;
}
.full {
    color: #e4002b;
}
.mode-display {
    position: absolute;
    margin-top: -70px;
    font-family: 'Roboto Mono';
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: lighter;
    color: darkgray;
}
.navbar .outer {
    width: 100px;
}
.navbar .spacer {
    width: 100px;
}
@media screen and (min-width: 900px) {
  .navbar {
    position: absolute;
    bottom: auto;
    height: 100%;
    width: 80px;
    border-radius: 0px 6px 6px 0px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .navbar.border {
    border-bottom: none;
  }
  .navbar .right, .navbar .left {
      flex-direction: column;
  }
  .navbar .nav-link {
      height: 120px;
  }
    .nav-link.tooltipHighlight {
        border: none;
        border-radius: 0px;
        background-color: transparent;
    }
  .navbar .outer.nav-link {
      margin-left: -180px;
  }
  .navbar .inner.nav-link {
      margin-left: -120px;
  }
  .navbar .outer.showNavLink, .navbar .inner.showNavLink {
      margin-left: 0;
  }
  .nav-button p {
    margin: 0;
    font-size: 12px;
    display: block;
  }
  .algo-button {
    flex-direction: column;
    justify-content: center;
    padding: 4px;
    width: 40px;
    border-radius: 4px;
  }
  .algo-button .material-icons {
    margin-right: 0px;
}
}
</style>