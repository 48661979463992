<template>
    <div class="details custom-scrollbar" :class="{overflowvisible: parent === 'collection'}">
        <transition name="slide">
            <Valuation v-if="showValuesBreakdown" :scoreObj="scoreObj" @close="showValuesBreakdown = false"/>
        </transition>
        <div ref="capture" class="information custom-scrollbar">
            <div v-if="mobile" class="object-image">
                <div v-if="preloading" class="zoom-image-preloader loading">Fetching image...</div>
                <inner-image-zoom :zoomScale="zoomScale" @click.prevent="preloadImage" :afterZoomOut="preloadImage" :fullscreenOnMobile="true" :mobileBreakpoint="2600" :hideCloseButton="true" :zoomPreload="true" :hideHint="true" :src="piece.primaryImageSmall" :zoomSrc="piece.primaryImage"/>
            </div>
            <div v-else class="object-image" :style="{height: placeholderHeight + 'px'}">
                <div v-if="!imageLoaded" class="image-preloader-wrapper">
                    <img :src="piece.primaryImageSmall" class="blur">
                    <div class="loading-highlight move-right"></div>
                </div>
                <transition name="fade">
                    <inner-image-zoom v-if="imageLoaded" :class="{noClick: loResImage, showOverflow: zoomed}" :afterZoomIn="afterZoomIn" :afterZoomOut="afterZoomOut" :fullscreenOnMobile="true" :mobileBreakpoint="2600" :hideCloseButton="true" :zoomPreload="true" :hideHint="true" :src="piece.primaryImage" :zoomSrc="piece.primaryImage" :zoomScale="1"/>
                </transition>
            </div>
            <ReactionBar
            v-if="!zoomed"
            @track-piece="AddToFeed('tracked')"
            @describe-piece="AddToFeed('described')"
            :piece="piece" />
            <div v-if="!zoomed" class="object-details">
                <p>
                    <span class="artist">{{piece.artistDisplayName == '' ? 'Unknown' : piece.artistDisplayName}}</span>
                    <br/>
                    <span v-if="piece.artistNationality || piece.artistBeginDate">
                        {{piece.artistNationality}}
                    </span>
                    <span v-if="piece.artistNationality && piece.artistBeginDate">, </span>
                    <span v-if="piece.artistBeginDate">
                        active {{handleDate(piece.artistBeginDate)}}-{{handleDate(piece.artistEndDate) == 'present' ? handleDate(piece.artistEndDate) : handleDate(piece.artistEndDate)}}
                    </span>
                </p>
                <p><span class="title">{{handleTitle(piece.title)}}</span>
                <br/>
                {{handleDate(piece.objectDate)}}
                <br/>
                {{piece.medium}}
                </p>
                <ViewCounter v-if="showViewCounter" :views="views"/>
                <OwnerBadge v-if="owner"
                :owner="owner"/>
                <div class="object-attributes">
                <!-- <ObjectScore
                @showvaluesbreakdown="showValuesBreakdown = true"
                :score="this.$store.state.GetObjectValue(piece).prettyScore"
                :showScore="true"/> -->
                <!-- <Clipper
                :piece="piece"
                :objectDate="handleDate(piece.objectEndDate)"
                @clip-object="ClipObject"/> -->
                </div>
                <div class="more-information">
                    <FlavorText
                    :objectURL="piece.objectURL" />
                    <div v-if="piece.additionalImages.length > 0" class="info-item images">
                        <p class="category">Additional images ({{piece.additionalImages.length}})</p>
                        <inner-image-zoom v-for="image in piece.additionalImages" :key="image" :fullscreenOnMobile="true" :mobileBreakpoint="2600" :hideCloseButton="true" :zoomPreload="true" :hideHint="true" :src="image" :zoomScale="zoomScale"/>
                    </div>
                    <div class="extended-info">
                        <div class="info-item" v-if="piece.dimensions">
                            <p class="category">Dimensions</p>
                            <p>{{piece.dimensions}}</p>
                        </div>
                        <div class="info-item" v-if="piece.department">
                            <p class="category">Department</p>
                            <p>{{piece.department}}</p>
                        </div>
                        <div class="info-item" v-if="piece.geographyType && piece.country">
                            <p class="category">{{piece.geographyType}}</p>
                            <p>{{piece.country}}</p>
                        </div>
                        <div class="info-item" v-if="piece.culture">
                            <p class="category">Culture</p>
                            <p>{{piece.culture}}</p>
                        </div>
                        <div class="info-item" v-if="piece.classification">
                            <p class="category">Classification</p>
                            <p>{{piece.classification}}</p>
                        </div>
                        <div class="info-item">
                            <p class="category">Gallery number</p>
                            <p>{{piece.GalleryNumber === '' ? 'Not on view' : piece.GalleryNumber}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Actions
        :owned="owner"
        :piece="piece"
        @collect-piece="CollectPiece($event)"
        @uncollect-piece="UncollectPiece($event)"/>
    </div>
</template>

<script>
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
import InnerImageZoom from 'vue-inner-image-zoom'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import db from '@/main'
import Actions from '@/components/Actions'
import Valuation from '@/components/Valuation'
// import ObjectScore from '@/components/ObjectScore'
import OwnerBadge from '@/components/OwnerBadge'
// import Clipper from '@/components/Clipper'
// import ShareSheet from '@/components/ShareSheet'
import ViewCounter from '@/components/ViewCounter'
import ReactionBar from '@/components/ReactionBar'
import FlavorText from '@/components/FlavorText'
import moment from 'moment'
export default {
    name: 'Details',
    props: {
        piece: Object,
        parent: String
    },
    data () {
        return {
            owner: null,
            mobile: true,
            placeholderHeight: null,
            imageLoaded: false,
            scoreObj: null,
            showValuesBreakdown: false,
            zoomed: false,
            loResImage: false,
            preloading: false,
            preloaded: false,
            currentURL: null,
            views: 0,
            flavorText: null,
            showFlavorText: false,
            showViewCounter: false,
            zoomScale: 0.55
        }
    },
    components: {
        InnerImageZoom, Actions, Valuation, OwnerBadge, ViewCounter, ReactionBar, FlavorText
    },
    methods: {
        hideDetailsOnSwipe() {
            this.$emit('close')
            this.imageLoaded = false
        },
        ExaminePiece() {
            if(this.$store.state.loggedIn) {
                db.collection('users').doc(this.$store.state.userData.userSlug).update({
                    examined: firebase.firestore.FieldValue.increment(1)
                })
            }

            var id = this.piece.objectID.toString()
            db.collection('global/reactions/' + id).doc('examinations').get().then((doc) => {
                if (doc.exists) {
                    db.collection('global/reactions/' + id).doc('examinations').update({
                        amount: firebase.firestore.FieldValue.increment(1)
                    })
                    
                } else {
                    db.collection('global/reactions/' + id).doc('examinations').set({
                        amount: 1
                    })
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            })
        },
        handleDate(year) {
            // To Do remove 0s before 3 digit years
            if (year !== '') {
                if(year <= 0) {
                    var plainYear = Math.abs(year)
                    return plainYear + " BC"
                } else if(year <= 1000) {
                    return year + " AD"
                } else if(year === '9999'){
                    return 'present'
                } else {
                    return year
                }
            } else {
                return 'ca. ' + this.piece.objectEndDate
            }
        },
        handleArtistName(name) {
            if(name == '') {
                return 'Unknown'
            } else {
                return name
            }
        },
        handleTitle(title){
            if(title && title.includes('<i>')) {
                var cleanTitle = title.replace('<i>', '')
                cleanTitle = cleanTitle.replace('</i>', '')
                return cleanTitle
            } else {
                return title
            }
        },
        CollectPiece(piece) {
            db.collection('users').doc(this.$store.state.userData.userSlug).update({
                collected: firebase.firestore.FieldValue.arrayUnion(piece)
            }).then(()=> {
                this.$store.commit('addToUserCollection', {
                    object: piece
                })
                this.owner = this.$store.state.userData.username
                this.AddToFeed('collected', piece)
            })
        },
        UncollectPiece(piece) {
            for(var i = 0; i < this.$store.state.userData.collected.length; i++) {
                if (this.$store.state.userData.collected[i].objectID === piece.objectID) {
                    this.$store.commit('removeFromUserCollection', {
                        index: i
                    })
                    db.collection('users').doc(this.$store.state.userData.userSlug).update({
                        collected: this.$store.state.userData.collected
                    })
                    this.owner = null
                    this.AddToFeed('released', piece)
                }
            }
        },
        AddToFeed(action, obj) {
            // Add to feed
            if (this.$store.state.userData.username !== 'testaccount') {
                var marketObj = {
                    date: moment(new Date()).format(),
                    user: this.$store.state.userData.username,
                    action: action,
                    piece: obj
                }
                db.collection('global').doc('feed').update({
                    marketActivity: firebase.firestore.FieldValue.arrayUnion(marketObj)
                })
            }
        },
        afterZoomIn() {
            this.zoomed = true
        },
        afterZoomOut() {
            this.zoomed = false
        },
        preloadImage() {
            if(!this.preloaded) {
                this.preloading = true
                this.preloaded = true
            } else {
                this.preloading = false
            }
        },
        CheckOwner(id) {
            db.collection('users').get().then(snapshot => {
                snapshot.forEach((doc) => {
                    for(var i = 0; i < doc.data().collected.length; i++) {
                        if (doc.data().collected[i].objectID === id) {
                            this.owner = doc.id
                        }
                    }
                })
            })
        },
        CheckViews(id) {
            db.collection('global/reactions/' + id).doc('examinations').get().then((doc) => {
                if (doc.exists) {
                    this.views = doc.data().amount
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            })
        },
        handleScroll(event) {
            console.log('scrolled', event)
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll)
        this.CheckOwner(this.piece.objectID)
        this.ExaminePiece()
    },
    mounted() {
        console.log(this.piece)
        // if (!this.$store.state.loggedIn) {
        //     this.$store.commit('lastSeenTemp', {
        //         obj: this.piece
        //     })
        // }
        this.CheckViews(this.piece.objectID.toString())
        this.$store.commit('openDetails', {
            isOpen: true
        })
        this.scoreObj = this.$store.state.GetObjectValue(this.piece)
        if(window.innerWidth > 500) {
            this.mobile = false
            let img = new Image()
            let imgLarge = new Image()
            img.src = this.piece.primaryImageSmall
            imgLarge.src = this.piece.primaryImage
            img.onload = () => {
                this.placeholderHeight = (img.height / img.width) * 900
            }
            imgLarge.onload = () => {
                this.imageLoaded = true
                if (imgLarge.width < 900) {
                    this.placeholderHeight = imgLarge.height
                    this.loResImage = true
                }
            }
                
        }
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
        this.owner = null
        this.$store.commit('openDetails', {
            isOpen: false
        })
    }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,wght@0,300;0,400;1,300;1,400&display=swap');
.details {
    background-color: black;
    z-index: 99;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 60px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
}
.details.overflowvisible {
    overflow-y: visible;
}
h2 {
    margin: 0;
}
.information {
    justify-content: start;
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    grid-area: 1 / 1 / 2 / 1;
    height: 100%;
    overflow-y: scroll;
    border-bottom: 1px solid #2c2c2c;
}
.object-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #050505;
    position: relative;
}
.iiz {
    width: 100%;
    display: flex;
    justify-content: center;
}
.iiz .iiz__img {
    width: 100% !important;
}
.object-attributes {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.object-attributes * {
    margin-right: 10px;
}
.object-details {
    z-index: 99;
    padding: 20px;
}
.category {
    color: #999999;
    margin-bottom: 4px;
}
.info-item {
    margin-bottom: 10px;
}

.info-item p {
    margin: 4px 0;
}
.info-item .iiz {
    margin-top: 10px;
}
.zoom-image-preloader, .image-preloader {
    position: absolute;
    margin: 0 auto;
    z-index: 9;
    color: black;
    background-color: white;
    padding: 6px 12px;
    border-radius: 30px;
    font-family: 'Roboto Mono';
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
}
.image-preloader-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    overflow: hidden;
}
.image-preloader {
    z-index: inherit;
}
.image-preloader-wrapper img {
    width: 100%;
    height: 100%;
    // filter: blur(15px) brightness(0.6);
}
.images {
    margin: 40px 0;
}
.extended-info {
    display: flex;
    flex-direction: column;
}
.noClick {
    pointer-events: none;
}
.title {
    font-size: 16px;
}
.loading-highlight {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 40px;
    background-color: white;
    opacity: .3;
    filter: blur(50px);
}
.move-right {
    animation: moveHighlight 1s cubic-bezier(.32,.69,.44,1) infinite;
}
@keyframes moveHighlight {
  from {left: 0%;}
  to {left: 120%;}
}
@media screen and (min-width: 900px) {
    .information {
        margin: 0 auto;
        overflow-x: hidden;
    }
    .zoom-image-preloader {
        display: none;
    }
    .iiz {
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    .iiz.showOverflow {
        overflow: visible;
    }
    .iiz__img {
        width: 100% !important;
        height: 100%;
    }
    .object-image {
        margin: 40px auto 0;
        width: 900px;
    }
    .object-details {
        width: 900px;
        margin: 0 auto;
    }
    .imagePlaceholder {
        width: 100%;
    }
    .extended-info {
        flex-direction: row;
        gap: 60px;
        margin-bottom: 100px;
    }
    .extended-info .info-item {
    }
}
</style>