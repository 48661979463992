<template>
    <div class="object-score cursor-pointer" @click="$emit('showvaluesbreakdown')">
        Score: {{score}}
    </div>
</template>

<script>
export default {
    name: 'ObjectScore',
    props: {
        score: String,
        showScore: Boolean
    },
    data() {
        return {
            
        }
    }

}
</script>

<style scoped>
.object-score {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: bold;
    color: white;
    transition: all 0.1s cubic-bezier(.32,.69,.44,1);
    width: 100%;
    text-align: right;
}
.object-score:hover {
    filter: brightness(1.5);
}
.material-icons.met-coin {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    margin-right: 8px;
    color: #fff;
}
.score {
    text-transform: uppercase;
    letter-spacing: 1px;
}
.prompt {
    text-align: center;
    font-family: "Roboto Mono", monospace;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    font-size: 11px;
    font-weight: 700;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s linear;
    margin-right: 8px;
}
</style>