<template>
  <div class="login">
      <h2>Log in</h2>
      <br/>
      <form @submit.prevent="Login">
        <input type="text" placeholder="Email" v-model="email" />
        <input type="password" placeholder="Password" v-model="password" />
        <input type="submit" value="Log in">
        <p><router-link to="/ResetPassword">Forgot password?</router-link></p>
        <p class="text-danger">{{feedback}}</p>
        <p><router-link to="/register">Create an account </router-link>to save pieces to your personal collection and participate in the global arts and antiquities market.</p>
      </form>
  </div>
</template>

<script>
import {ref} from 'vue'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const router = useRouter()
    const email = ref('')
    const password = ref('')
    let feedback = ref('')
    const Login = () => {
      if(email.value !== '' && password.value !== '') {
        firebase
          .auth()
          .signInWithEmailAndPassword(email.value, password.value)
          .then(() => {
            console.log('Welcome back!')
          })
          .catch(err => {
            if (err.code == 'auth/wrong-password') {
              feedback.value = 'Incorrect email or password.'
            } else {
              feedback.value = err.message
            }
          })
      } else {
        feedback.value = 'Please enter an email address and a password.'
      }
    }

    return {
      Login, email, password, feedback, router
    }
  },
}
</script>

<style scoped>
.login {
  background-color: black;
  padding: 20px;
  grid-area: 1 / 1 / 4 / 1;
  width: 300px;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  margin: 0 auto;
  text-align: center;
}
.login form {
  display: flex;
  flex-direction: column;
  width: 300px;
}
</style>