<template>
    <transition name="fade">
        <div v-if="flavorText" @click="showFlavorText = !showFlavorText" class="flavor-text-container" :class="{closed: !showFlavorText && this.$store.state.isMobile}">
            <div v-html="flavorText" :class="{clamp: !showFlavorText && this.$store.state.isMobile}" class="flavor-text"></div>
            <br/>
            <a :href="objectURL" target="_blank" v-if="showFlavorText || !this.$store.state.isMobile">Source</a>
            <div v-if="!showFlavorText && this.$store.state.isMobile" class="read-more-gradient">Read more</div>
            <div v-if="showFlavorText && this.$store.state.isMobile" class="read-more-gradient">Collapse</div>
        </div>
    </transition>
</template>

<script>
import axios from 'axios'
import cheerio from 'cheerio'
import nlp from 'compromise'
export default {
    name: 'FlavorText',
    props: {
        objectURL: String
    },
    data () {
        return {
            flavorText: null,
            showFlavorText: false,
            adjectives: []
        }
    },
    methods: {
        GetFlavorText(url) {
            axios.get(url)
            .then((response) => {
                const $ = cheerio.load(response.data)
                var text = $('p', '.artwork__intro__desc').text()
                var html = $('p', '.artwork__intro__desc').html()
                if (text) {
                    this.$store.commit('flavorText', {
                        flavorText: text
                    })
                    this.flavorText = html
                    let doc = nlp(text)
                    let adjArr = doc.adjectives().json()
                    for(var i = 0; i < adjArr.length; i++) {
                        this.adjectives.push(adjArr[i].text)
                    }
                    this.$store.commit('flavorTextAdjectives', {
                        adjectives: this.adjectives
                    })
                } else {
                    this.$store.commit('flavorText', {
                        flavorText: 'none'
                    })
                }
            })
            .catch((error) => {
                console.log(error)
            })
        }
    },
    mounted() {
        this.$store.commit('flavorText', {
            flavorText: null
        })
        this.GetFlavorText(this.objectURL)
    }
}
</script>

<style scoped>
.flavor-text-container {
    margin: 20px 0 60px 0;
    box-sizing: border-box;
    cursor: pointer;
    padding: 20px;
    background-color: #fff0e0;
    border-radius: 2px;
    font-family: 'Roboto Serif';
    position: relative;
}
.flavor-text-container.closed {
    padding-bottom: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.flavor-text {
    line-height: 26px;
    color: #000;
}
a {
    color: #000 !important;
    border-bottom: 1px solid #000;
}
.read-more-gradient {
    bottom: -16px;
    left: 0;
    cursor: pointer;
    position: absolute;
    color: black;
    width: 100%;
    border: none;
    transform: translateY(4px);
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    border-top: 1px solid #ddd0bf;
    background-color: #ffebd1;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}
.clamp {
    display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media screen and (min-width: 900px) {
    .flavor-text-container {
        cursor: default;
    }
    .flavor-text {
        font-size: 16px;
        line-height: 26px;
        height: fit-content;
    }
    /* .flavor-text::first-letter {
        float: left;
        padding: 20px 20px 0 0;
        color: #e4002b;
        font-size: 80px;
        margin-bottom: 14px;
    } */
}
</style>