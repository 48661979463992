import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Register from '../views/Register.vue'
import Settings from '../views/Settings.vue'
import Collection from '../views/Collection.vue'
import Inbox from '../views/Inbox.vue'
import Display from '../components/Display.vue'
import Feed from '../components/Feed.vue'
import Global from '../views/Global.vue'
import ViewObject from '../views/Object.vue'
import Explore from '../views/Explore.vue'
import {store} from '../main.js'

const routes = [
  {
    path: '/',
    name: Display,
    component: () => import('../components/Display.vue'),
    meta: {
      transition: 'fade',
      title: 'Metitate.art - Explore The Met'
    }
  },
  {
    path: '/settings',
    name: Settings,
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/login',
    name: Login,
    component: () => import('../views/Login.vue')
  },
  {
    path: '/resetpassword',
    name: ResetPassword,
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/register',
    name: Register,
    component: () => import('../views/Register.vue')
  },
  {
    path: '/collection',
    name: Collection,
    component: () => import('../views/Collection.vue')
  },
  {
    path: '/inbox',
    name: Inbox,
    component: () => import('../views/Inbox.vue')
  },
  {
    path: '/global',
    name: Global,
    component: () => import('../views/Global.vue')
  },
  {
    path: '/object',
    name: ViewObject,
    component: () => import('../views/Object.vue'),
    meta: {transition: 'slide-fade'}
  },
  {
    path: '/feed',
    name: Feed,
    component: () => import('../components/Feed.vue')
  },
  {
    path: '/explore',
    name: Explore,
    component: () => import('../views/Explore.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  if (from.name) {
    //console.log(from.name.name + ' to ', to.name.name)
    if (from.name.name !== 'Display') {
      store.commit('updateStatus', {type: 'resuming', message: 'Picking up where you left off...', ready: true})
    }
  }
  // ...
  // explicitly return false to cancel the navigation
})
export default router
