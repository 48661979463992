<template>
    <div class="object-view">
        <Details v-if="piece"
        :piece="piece"/>
    </div>
</template>

<script>
import Details from '@/components/Details'
import { useRoute } from 'vue-router'
import axios from 'axios'
import tinykeys from "tinykeys"
import router from '../router/index.js'
// import CryptoJS from 'crypto-js'
export default {
    name: 'ViewObject',
    components: {
        Details
    },
    data() {
        return {
            piece: null
        }
    },
    mounted() {
        const route = useRoute()
        // var decrypted = CryptoJS.AES.decrypt(route.query.id, "the met").toString(CryptoJS.enc.Utf8)
        axios.get('https://collectionapi.metmuseum.org/public/collection/v1/objects/' + route.query.id)
        .then((response) => {
            this.piece = response.data
        })
        tinykeys(window, {
            "v": () => {
                router.push("/")
            }
        })
    }
}
</script>

<style scoped>
    .object-view {
        width: 100%;
        grid-area: 2 / 1 / 4 / 1;
        height: 100%; 
        background-color: black;
        padding: 0px;
        box-sizing: border-box;
        overflow: hidden;
    }
    @media screen and (min-width: 900px) {
        .object-view {
            grid-area: 1 / 1 / 4 / 1;
        }
    }
</style>