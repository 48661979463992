<template>
    <p v-if="views > 0" @click="copyLink()" class="view-counter">{{views}} {{views > 1 ? 'views' : 'view'}}</p>
</template>

<script>
export default {
    name: 'ViewCounter',
    props: {
        views: Number,
    },
    data() {
        return {
        }
    },
    methods: {
    }

}
</script>

<style scoped>
.view-counter {
    display: flex;
    align-items: center;
    /* background-color: #191d24;
    border: 2px solid #262c46;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 7px;
    border-radius: 6px;
    color: #fff;
    font-family: "Roboto Mono";
    font-size: 11px;
    text-transform: uppercase;
    box-shadow: 0px 2px 0px 0px #151c28;
    letter-spacing: 1px;
    pointer-events: none; */
}
.material-icons {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border-radius: 100%;
    margin-right: 8px;
}
</style>