<template>
    <div v-touch:swipe.right="backOne" v-touch:swipe.left="forwardOne" class="display">
        <transition name="slide-down-fade">
            <div v-if="this.$store.state.currentDepts && currentObject === null && this.$store.state.status.type !== 'resuming'" class="loading-screen">
                <DeptInfo :currentDepts="this.$store.state.currentDepts" :loader="true" />
            </div>
            <div v-else-if="this.$store.state.status.type !== 'ready' && this.$store.state.status.type !== 'resuming'" class="loading-screen">
                <div class="square-rotate-2"></div>
                <p>Slow down. Look at art.</p>
            </div>
            <div v-else-if="this.alerting" class="loading-screen alert">
                <p>{{alertText}}</p>
            </div>
        </transition>
        <div v-if="mode === 'focus'" class="focus">
            <div class="piece" @mousedown.prevent="pauseTimer()" @mouseup.prevent="pauseTimer()" @touchstart.prevent="pauseTimer()" @touchend.prevent="pauseTimer()" ref="pieceContainer">
                <img v-if="currentObject" class="piece-img" :class="{fadeIn: rewinding}" :src="currentObject.primaryImageSmall">
            </div>
            <transition name="fade" mode="out-in">
                <div v-if="currentObject" class="info-preview" :key="currentObject.title" :style="{maxWidth: pieceContainer.width}">
                    <div class="copy">
                        <p class="artist">{{currentObject.artistDisplayName == '' ? 'Unknown' : currentObject.artistDisplayName}}</p>
                        <p><span class="title">{{handleTitle(currentObject.title)}}</span>, {{handleDate(currentObject.objectEndDate)}}</p>
                    </div>
                    <router-link :to="'/object?id=' + currentObject.objectID" @click="pauseTimer()" class="button-wide">Details</router-link>
                </div>
            </transition>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import tinykeys from "tinykeys"
import firebase from 'firebase/compat/app'
import DeptInfo from '@/components/DeptInfo'
import 'firebase/compat/auth'
import db from '@/main'
var Chance = require('chance');
var chance = new Chance();
export default {
    name: 'Display',
    components: {
        DeptInfo
    },
    data() {
        return {
            activeDepartments: null,
            piece: null,
            prevPiece: null,
            currentObject: null,
            nextObject: null,
            seconds: 0,
            duration: 5,
            progress: 0,
            paused: false,
            timeout: null,
            details: false,
            debug: false,
            debugPiece: 435729, //owned by brainnews
            merchantID: 394750,
            merchant: false,
            merchantChance: 0,
            objectCounter: 0,
            showSettings: false,
            tabFocus: true,
            examined: false,
            todaysCollection: 0,
            searching: false,
            searchAttempts: 0,
            schedule: null,
            path: null,
            rewinding: false,
            alerting: false,
            alertText: '',
            canGoBack: false,
            mode: 'focus',
            gridArray: [],
            pieceContainer: {
                width: null
            }
        }
    },
    watch: {
        tabFocus() {
            if(!this.details) {
                this.pauseTimer()
            }
        }
    },
    methods: {
        showDetailsOnSwipe() {
            if (!this.showSettings && !this.details) {
                this.details = true
                this.pauseTimer()
            }
        },
        detectFocusOut() {
            let inView = false;
            this.tabFocus  = true
            const onWindowFocusChange = (e) => {
                if ({ focus: 1, pageshow: 1 }[e.type]) {
                    if (inView) return;
                    this.tabFocus = true;
                    inView = true;
                    this.$store.commit('updateTabFocus', {
                        tabFocus: true
                    })
                } else if (inView) {
                    this.tabFocus = !this.tabFocus;
                    inView = false;
                    this.$store.commit('updateTabFocus', {
                        tabFocus: false
                    })
                }
            };

            window.addEventListener('focus', onWindowFocusChange);
            window.addEventListener('blur', onWindowFocusChange);
            window.addEventListener('pageshow', onWindowFocusChange);
            window.addEventListener('pagehide', onWindowFocusChange);
        },
        SearchDepartments(dept) {
            for (var i = 0; i < this.$store.state.collectionAreas.length; i++) {
                if (this.$store.state.collectionAreas[i].name === dept) {
                    return this.$store.state.collectionAreas[i]
                }
            }
        },
        ShuffleCollections(playlist){
            var departments = playlist

            let galleries = []

            for (var i = 0; i < 7; i++) {
                var randomIndex = Math.floor(Math.random()*departments.length)
                let gallery = departments.splice(randomIndex, 1)
                galleries.push(gallery[0])
            }
            
            return galleries
        },
        isDateMoreThanAWeekAgo(date) {
            const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // 7 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds

            // Calculate the time difference between the current date and the provided date
            const currentDate = new Date();
            const providedDate = new Date(date.toDate());
            const timeDifference = currentDate - providedDate;

            // Compare the time difference with one week in milliseconds
            return timeDifference > oneWeekInMilliseconds;
        },
        SetActiveDepartments() {
            // Get todays collection
            const d = new Date()
            let day = d.getDay()
            if (this.$store.state.loggedIn && this.$store.state.userData.preferences.playlist && this.$store.state.userData.preferences.playlist.active) {
                const outOfDate = this.isDateMoreThanAWeekAgo(this.$store.state.userData.preferences.playlist.schedule.dateCreated)
                if (outOfDate) {
                    const currentDate = new Date()
                    let depts = []
                    for(var i = 0; i < this.$store.state.userData.preferences.playlist.list.length; i++) {
                        depts.push(this.$store.state.userData.preferences.playlist.list[i])
                    }
                    const newList = this.ShuffleCollections(depts)
                    db.collection('users').doc(this.$store.state.userData.userSlug).update({
                        "preferences.playlist.schedule.dateCreated": currentDate,
                        "preferences.playlist.schedule.collections": newList
                    }).then(()=> {
                        this.todaysCollection = newList[day]
                        this.$store.commit('getSchedule', {
                            schedule: newList
                        })
                        this.GetDepartmentData()
                    })
                } else {
                    this.todaysCollection = this.$store.state.userData.preferences.playlist.schedule.collections[day]
                    this.$store.commit('getSchedule', {
                        schedule: this.$store.state.userData.preferences.playlist.schedule.collections
                    })
                    this.GetDepartmentData()
                }
                
            } else {
                db.collection("global").doc("schedule").get().then((doc) => {
                    this.todaysCollection = doc.data().collections[day]
                    this.$store.commit('getSchedule', {
                        schedule: doc.data().collections
                    })
                }).then(() => {
                    this.GetDepartmentData()
                })
            }
        },
        GetDepartmentData() {
            var deptObj = this.SearchDepartments(this.todaysCollection)
            //Check if IDs are already stored in local storage
            if (localStorage.getItem(this.todaysCollection)) {
                const storedIDs = localStorage.getItem(this.todaysCollection).split(',')
                this.$store.commit('getObjects', {
                    IDs: storedIDs
                })
                this.$store.commit('setCurrentDepts', {
                    name: deptObj.name,
                    previewImage: deptObj.previewImage,
                    objectCount: this.$store.state.objects.count,
                    description: deptObj.description,
                    url: deptObj.url,
                    minScore: deptObj.minScore
                })
                this.preloadObject()
            } else {
                axios.get('https://collectionapi.metmuseum.org/public/collection/v1/objects?departmentIds=' + deptObj.id)
                .then((response) => {
                    //Save IDs to local storage
                    localStorage.setItem(this.todaysCollection, response.data.objectIDs)
                    // Count all objects in API
                    this.$store.commit('getObjects', {
                        amount: response.data.total,
                        IDs: response.data.objectIDs
                    })
                })
                .finally(() => {
                    this.$store.commit('setCurrentDepts', {
                        name: deptObj.name,
                        previewImage: deptObj.previewImage,
                        objectCount: this.$store.state.objects.count,
                        description: deptObj.description,
                        url: deptObj.url,
                        minScore: deptObj.minScore
                    })
                    this.preloadObject()
                })
            }
        },
        pauseTimer() {
            if (!this.paused) {
                clearTimeout(this.timeout)
                this.paused = true
            } else {
                clearTimeout(this.timeout)
                this.seconds = this.seconds - 1
                this.paused = false
                this.timer()
            }
        },
        timer() {
            if (this.$store.state.status.type !== 'searching') {
                if(this.$store.state.status.type === 'ready' && !this.paused && this.seconds < this.$store.state.interval) {
                    this.seconds++
                    this.$emit('tick', this.seconds)
                    this.timeout = setTimeout(() => this.timer(), 1000)
                } else if (this.$store.state.status.type === 'ready' && !this.paused && this.seconds === this.$store.state.interval) {
                    this.examined = false
                    this.seconds = 0
                    this.showObject()   
                } else {
                    clearTimeout(this.timeout)
                }
            }
        },
        SeePiece(obj, imageURL) {
            let day = new Date().getDay()
            obj.daySeen = day
            if(this.$store.state.loggedIn) {
                db.collection('users').doc(this.$store.state.userData.userSlug).update({
                    seen: firebase.firestore.FieldValue.increment(1),
                    lastSeen: obj
                })
                this.$store.commit('updateLastSeen', {
                    URL: imageURL,
                    obj: obj
                })
            } else {
                this.$store.commit('lastSeenTemp', {
                    obj: obj
                })
            }
        },
        handleDate(year) {
            if(year <= 0) {
                var plainYear = Math.abs(year)
                return plainYear + " BC"
            } else if(year <= 1000) {
                return year + " AD"
            } else {
                return year
            }
        },
        handleTitle(title){
            if(title && title.includes('<i>')) {
                var cleanTitle = title.replace('<i>', '')
                cleanTitle = cleanTitle.replace('</i>', '')
                return cleanTitle
            } else {
                return title
            }
        },
        showSpecificObject(obj){
            this.currentObject = obj
            this.SeePiece(obj, obj.primaryImageSmall)
            if (!this.rewinding) {
                this.preloadObject()
            }
            clearTimeout(this.timeout)
            this.$store.commit('updateStatus', {
                message: '',
                ready: true,
                type: 'ready'
            })
            this.timer()
        },
        randomID() {
            var randomIndex = chance.integer({
                min: 0,
                max: this.$store.state.objects.IDs.length
            })
            return this.$store.state.objects.IDs[randomIndex]
        },
        preloadObject() {
            axios.get('https://collectionapi.metmuseum.org/public/collection/v1/objects/' + this.randomID())
            .then((response) => {
                // LOGGED IN USER
                if (this.$store.state.loggedIn) {
                    if(this.$store.state.userData.preferences.algo && response.data.primaryImageSmall !== '' && this.$store.state.GetObjectValue(response.data).score > this.$store.state.currentDepts.minScore) {
                        console.log('Accepted:\n' + response.data.title + '\nby ' + response.data.artistDisplayName + '\nScore: ' + this.$store.state.GetObjectValue(response.data).score + '\n' + response.data.objectURL + '\nHighlight: ' + response.data.isHighlight)
                        this.rejectedObject = null
                        this.nextObject = response.data
                        if (this.currentObject === null) {
                            setTimeout(() => {
                                this.$store.commit('updateStatus', {
                                    message: '',
                                    ready: true,
                                    type: 'ready'
                                })
                                this.showObject()
                            }, 2000)
                        }
                    } else if (!this.$store.state.userData.preferences.algo && response.data.primaryImageSmall !== '') {
                        this.rejectedObject = null
                        this.nextObject = response.data
                        if (this.currentObject === null) {
                            setTimeout(() => {
                                this.$store.commit('updateStatus', {
                                    message: '',
                                    ready: true,
                                    type: 'ready'
                                })
                                this.showObject()
                            }, 2000)
                        }
                    } else {
                        this.rejectedObject = response.data
                        // console.log('Rejected:\n' + this.rejectedObject.title + '\nby ' + this.rejectedObject.artistDisplayName + '\nScore: ' + this.$store.state.GetObjectValue(response.data).score + '\n' + this.rejectedObject.objectURL)
                        this.preloadObject()
                    }
                // LOGGED OUT USER
                } else {
                    if (response.data.primaryImageSmall !== '' && this.$store.state.GetObjectValue(response.data).score > this.$store.state.currentDepts.minScore) {
                        //READ OUT
                        // console.log('Accepted:\n' + response.data.title + '\nby ' + response.data.artistDisplayName + '\nScore: ' + this.$store.state.GetObjectValue(response.data).score + '\n' + response.data.objectURL)

                        this.rejectedObject = null
                        this.nextObject = response.data
                        if (this.currentObject === null) {
                            setTimeout(() => {
                                this.$store.commit('updateStatus', {
                                    message: '',
                                    ready: true,
                                    type: 'ready'
                                })
                                this.showObject()
                            }, 2000)
                        }
                    } else {
                        this.rejectedObject = response.data
                        // console.log('Rejected:\n' + this.rejectedObject.title + '\nby ' + this.rejectedObject.artistDisplayName + '\nScore: ' + this.$store.state.GetObjectValue(response.data).score + '\n' + this.rejectedObject.objectURL)
                        this.preloadObject()
                    }
                }
            })
        },
        showObject() {
            if (this.currentObject) {
                this.canGoBack = true
                this.prevObject = this.currentObject
            }
            this.currentObject = this.nextObject
            this.pieceContainer.width = this.$refs.pieceContainer.clientWidth
            if (this.mode === 'grid') {
                if (this.gridArray.length < 8) {
                    this.gridArray = [...this.gridArray, this.currentObject]
                } else {
                    const i = Math.floor(Math.random() * 9);
                    this.gridArray[i] = null
                    this.gridArray[i] = this.currentObject
                }
            }
            if (!this.rewinding) {
                this.preloadObject()
            }
            this.$store.commit('updateStatus', {
                message: '',
                ready: true,
                type: 'ready'
            })
            this.SeePiece(this.currentObject, this.currentObject.primaryImageSmall)
            clearTimeout(this.timeout)
            this.timer()
        },
        backOne() {
            if (this.prevObject && this.canGoBack) {
                this.seconds = 0
                this.rewinding = true
                this.showSpecificObject(this.prevObject)
                setTimeout(() => {
                    this.rewinding = false
                }, 2000);
            } else {
                this.alertText = 'Rewind unavailable'
                this.alerting = true
                setTimeout(() => {
                    this.alerting = false
                }, 2000);
            }
            this.canGoBack = false
        },
        forwardOne() {
            this.alertText = 'New object available in ' + (this.$store.state.interval - this.seconds) + ' seconds'
            this.alerting = true
            setTimeout(() => {
                this.alerting = false
            }, 2000);
            // if (this.nextObject) {
            //     this.seconds = 0
            //     this.showObject()
            // }
        }
    },
    mounted() {
        this.path = window.location.href
        this.detectFocusOut()
        if (this.$store.state.status.type === 'resuming' && this.$store.state.status.mod === 'playlist change') {
            this.SetActiveDepartments()
        } else if(this.$store.state.status.type === 'resuming' && this.$store.state.loggedIn) {
            this.showSpecificObject(this.$store.state.userData.lastSeen)
        } else if (this.$store.state.status.type === 'resuming' && !this.$store.state.loggedIn) {
            if (this.$store.state.lastSeenTemp) {
                this.showSpecificObject(this.$store.state.lastSeenTemp)
            } else {
                this.SetActiveDepartments()
            }
        } else if (this.$store.state.status.type === 'loggedOut' && !this.$store.state.loggedIn) {
            this.SetActiveDepartments()
        } else {
            setTimeout(()=> {
                this.SetActiveDepartments()
            }, 2000)
            
        }
        tinykeys(window, {
            "v": () => {
                // router.push('/object?id=' + this.currentObject.objectID)
                // this.pauseTimer()
            }
        }) 
        tinykeys(window,
        {
            "Space": () => {
                if(!this.details) {
                    this.pauseTimer()
                }
            }
        })
        tinykeys(window,
        {
            "Space": () => {
                if(!this.details) {
                    this.pauseTimer()
                }
            },
            event: "keyup"
        })
    },
    created() {
        
    }
}
</script>

<style lang="scss">
.display {
    width: 100%;
    grid-area: 1 / 1 / 4 / 1;
    height: 100%; 
    background-color: black;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 40px 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    padding: 0px;
    box-sizing: border-box;
    overflow: hidden;
}
.alert {
    z-index: 9;
    background-color: rgb(0 0 0 / 83%);
}
.focus {
    display: flex;
    align-items: center;
    justify-items: center;
    grid-area: 1 / 1 / 3 / 1;
    padding: 20px;
    margin: 0 auto;
    flex-direction: column;
}
.info-preview {
    grid-area: 3 / 1 / 3 / 1;
    position: absolute;
    bottom: 0px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    padding: 20px;
    display: flex;
    align-items: flex-start !important;
    flex-direction: column;
    justify-content: space-between;
    background-color: #00000046;
}
.info-preview .copy {
    margin-right: 20px;
}
.focus .piece img {
    max-width: 100%;
    max-height: 100%;
    z-index: 1;
    border-radius: 2px;
}
.loading-screen {
    // padding: 20px 20px 60px 20px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    flex-direction: column;
}
.title {
    font-style: italic;
}
.info-preview .button-wide {
    margin-bottom: 0;
    width: 100% !important;
    margin-top: 14px;
    border: 1px solid #2c2c2c;
    border-radius: 6px;
    padding: 8px;
    text-align: center;
}
.info-preview p {
    margin: 4px 0 0 0;
}
.artist {
    font-size: 16px;
    margin-top: 0;
}
p.objectsReady {
    color: #83fb83;
}

@keyframes square-rotate-2-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 500px) {
}
@media screen and (min-width: 900px) {
    .info-preview {
        max-width: 900px;
        margin: 0 auto;
        border-radius: 6px 6px 0 0;
        position: initial;
        grid-area: 3/1/3/4;
        flex-direction: row;
        justify-content: space-between;
        align-items: center !important;
    }
    .info-preview .button-wide {
        width: 200px !important;
        margin-top: 0px;
    }
    .display {
        grid-template-rows: 40px 1fr 85px;
    }
    .focus {
        grid-area: 1 / 1 / 4 / 1;
    }
    .top-bar {
        justify-content: start;
    }
    .grid {
        display: grid;
        grid-template-columns: .25fr .25fr .25fr .25fr;
        grid-template-rows: 50% 50%;
        grid-area: 1 / 1 / 4 / 1;
        grid-gap: 10px;
        width: 80vw;
        margin: auto;
    }
    .grid .grid-item-1 {
        grid-area: 1 / 1;
        align-self: end;
        display: flex;
        align-items: flex-end;
        justify-content: end;
    }
    .grid-item-1 .piece {
        width: 80%;
        height: 80%;
        margin: 0;
    }
    .grid .grid-item-2 {
        grid-area: 1 / 2;
        align-self: end;
    }
    .grid .grid-item-3 {
        grid-area: 1 / 3;
        align-self: end;
    }
    .grid .grid-item-4 {
        grid-area: 1 / 4;
        align-self: end;
        display: flex;
        justify-content: flex-start;
    }
    .grid-item-4 .piece {
        width: 90%;
        height: 90%;
        margin: 0;
    }
    .grid .grid-item-5 {
        grid-area: 2 / 1;
        align-self: start;
        display: flex;
        justify-content: flex-end;
    }
    .grid-item-5 .piece {
        width: 90%;
        height: 90%;
        margin: 0;
    }
    .grid .grid-item-6 {
        grid-area: 2 / 2;
        align-self: start;
    }
    .grid .grid-item-7 {
        grid-area: 2 / 3;
        align-self: start;
    }
    .grid .grid-item-8 {
        grid-area: 2 / 4;
        align-self: start;
        display: flex;
        align-items: flex-start;
        justify-content: start;
    }
    .grid-item-8 .piece {
        width: 80%;
        height: 80%;
        margin: 0;
    }
    .grid .piece {
        padding: 0;
    }
}
</style>