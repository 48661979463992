<template>
  <div class="login">
      <h2>Reset password</h2>
      <br/>
      <div v-if="linkSent" class="success">
        <p>A password reset link was sent to {{email}}.</p>
        <p><router-link to="/Login">Back to login</router-link></p>
      </div>
      <form v-else @submit.prevent="resetPassword">
        <input type="text" placeholder="Email" v-model="email" />
        <input type="submit" value="Send password reset link">
        <p class="text-danger">{{feedback}}</p>
        <p><router-link to="/Login">Back to login</router-link></p>
      </form>
  </div>
</template>

<script>
import {ref} from 'vue'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default {
  setup() {
    let email = ref('')
    let feedback = ref('')
    let linkSent = ref('')
    const resetPassword = () => {
      firebase.auth().sendPasswordResetEmail(email.value)
      .then(() => {
        linkSent.value = true
      })
      .catch((error) => {
        feedback.value = error.message
        console.log(error.message)
      });
    }

    return {
      resetPassword, email, feedback, linkSent
    }
  },
}
</script>

<style scoped>
.login {
  background-color: black;
  padding: 20px;
  grid-area: 1 / 1 / 4 / 1;
  width: 300px;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  margin: 0 auto;
  text-align: center;
}
.login form {
  display: flex;
  flex-direction: column;
  width: 300px;
}
</style>