<template>
    <div  @click="share()" class="share-button"><span class="material-icons">reply</span></div>
</template>

<script>
// import CryptoJS from 'crypto-js'
export default {
    name: 'ShareButton',
    props: {
        piece: Object
    },
    data() {
        return {
            copied: false
        }
    },
    methods: {
        objectClassification() {
            switch(this.piece.classification) {
                case 'Paintings':
                    return '🖼️'
                case 'Musical Instruments':
                    return '🎼'
                case 'Stone Sculpture':
                    return '🗿'
                case 'Photographs':
                    return '📷'
                case 'Jewelry, Precious Metals and Precious Stones':
                    return '💎'
                case 'Metalwork':
                    return '⚒️'
                case 'Drawings':
                    return '✏️'
                case 'Ceramics-Pottery':
                    return '🏺'
                default:
                    return '🎨'
            }
        },
        share() {
            const artistName = this.piece.artistDisplayName == '' ? 'Unknown' : this.piece.artistDisplayName
            const emoji = this.objectClassification()
            const shareData = {
                title: this.piece.title + ' by ' + artistName,
                url: 'https://www.metitate.art/object?id=' + this.piece.objectID,
                text: this.piece.title + ' by ' + artistName + ' ' + emoji + ' '
            }
            navigator.share(shareData)
        },
        copyLink() {
            var path = window.location.href
            //var str = this.id.toString()
            // const encryptedID = CryptoJS.AES.encrypt(str, "the met").toString()
            const url = path
            navigator.clipboard.writeText(url)
            this.copied = true
            setTimeout(() => {
                this.copied = false
            }, 2000);
        }
    }

}
</script>

<style scoped>
.share-button {
    display: flex;
    align-items: center;
    /* background-color: #24191d;
    border: 2px solid #462626; */
    /* width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; */
    /* padding: 4px 7px; */
    /* border-radius: 6px; */
    color: #fff;
    font-family: "Roboto Mono";
    /* font-size: 11px; */
    text-transform: uppercase;
    /* box-shadow: 0px 2px 0px 0px #281515; */
    cursor: pointer;
    letter-spacing: 1px;
    transition: all 0.1s cubic-bezier(.32,.69,.44,1);
}
.share-button:hover {
    filter: brightness(1.5);
}
.material-icons {
    transform: rotateY(180deg);
}
</style>