<template>
    <div>
        <h2>Playlist<br/><span class="text-light">Week of {{weekStart}}</span></h2>
        <ul class="unstyled inset">
            <p v-if="this.$store.state.userData.preferences.playlist.active" class="text-light">Personal playlist refreshes every 7 days.</p>
            <p v-else class="text-light">Global playlist refreshes every week on Sunday</p>
            <li :class="{today: today == index}" class="line-item text-light" v-for="(day, index) in localSchedule" :key="index"><span class="day">{{day[0]}}</span><span class="dots"></span><span>{{day[1]}}</span></li>
        </ul>
        
    </div>
</template>

<script>
import moment from 'moment'
export default {
    name: "Schedule",
    props: {
        schedule: Array
    },
    data() {
        return {
            localSchedule: [],
            daysArray: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            today: null,
            weekStart: null
        }
    },
    methods: {
        buildSchedule() {
            for(var i = 0; i < this.$store.state.schedule.length; i++) {
                this.localSchedule.push([this.daysArray[i], this.$store.state.schedule[i]])
            }
        },
        getWeekStart(d) {
            d = new Date(d);
            var day = d.getDay()
            var diff = d.getDate() - day // adjust when day is sunday
            var date = new Date(d.setDate(diff));
            this.weekStart = moment(date).format('M[/]DD[/]YY')
        }
    },
    mounted() {
        this.buildSchedule()
        this.getWeekStart(new Date())
        this.today = new Date().getDay()
    }
}
</script>

<style>
.today {
    font-weight: bold;
    color: white;
}
h2 {
    margin: 0;
}
ul.inset {
    background-color: #202020;
    padding: 8px;
    border-radius: 8px;
}
ul.inset p {
    margin-bottom: 5px;
    margin-top: 0px;
    font-size: 11px;
    text-transform: uppercase;
    font-family: 'Roboto Mono';
    letter-spacing: .4px;
}
</style>