<template>
    <div v-if="this.$store.state.loggedIn && ready" class="heart-button">
            <span v-if="!hearted" @click.prevent="LikeObject()" class="material-icons">favorite_outline</span>
            <span v-else @click.prevent="UnlikeObject()" class="material-icons hearted">favorite</span>
    </div>
    <div v-else class="heart-button">
        <router-link to="/login"><span class="material-icons">favorite_outline</span></router-link>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import db from '@/main'
export default {
    props: {
        id: Number,
        showCount: Boolean,
        piece: Object
    },
    data () {
        return {
            hearted: false,
            heartCount: 0,
            ready: false
        }
    },
    methods: {
        HeartObject(id) {
            const str = id.toString()
            db.collection('global').doc('reactions/' + str + '/reactions').get().then((doc) => {
                if (doc.exists) {
                    console.log('doc exists')
                    //if user already hearted object
                    if (doc.data().hearts.indexOf(this.username) > -1) {
                        db.collection('global').doc('reactions/' + str + '/reactions').update({
                            hearts: firebase.firestore.FieldValue.arrayRemove(this.username)
                        })
                    } else {
                        db.collection('global').doc('reactions/' + str + '/reactions').update({
                            hearts: firebase.firestore.FieldValue.arrayUnion(this.username)
                        })
                    }
                    this.CheckHearts(id)
                } else {
                    console.log('doc does not exist yet')
                    db.collection('global').doc('reactions/' + str + '/reactions').set({
                        hearts: [this.username]
                    }).then(() => {
                        this.CheckHearts(id)
                    })
                }
                this.$emit('heart-piece')
            }).catch((error) => {
                console.log("Error getting document:", error);
            })
        },
        LikeObject() {
            db.collection('users').doc(this.$store.state.userData.userSlug).update({
                liked: firebase.firestore.FieldValue.arrayUnion(this.piece)
            }).then(()=> {
                this.$store.commit('addToUserLikes', {
                    object: this.piece
                })
                this.hearted = true
            })
        },
        UnlikeObject() {
            for(var i = 0; i < this.$store.state.userData.liked.length; i++) {
                if (this.$store.state.userData.liked[i].objectID === this.piece.objectID) {
                    this.$store.commit('removeFromUserLikes', {
                        index: i
                    })
                    db.collection('users').doc(this.$store.state.userData.userSlug).update({
                        liked: this.$store.state.userData.liked
                    })
                    this.hearted = false
                }
            }
        },
        CheckHearts(id) {
            const str = id.toString()
            db.collection('global').doc('reactions/' + str + '/reactions').get().then((doc) => {
                if (doc.exists) {
                    if (doc.data().hearts.indexOf(this.username) > -1) {
                        this.hearted = true
                    } else {
                        this.hearted = false
                    }
                    this.heartCount = doc.data().hearts.length
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            })
        },
        CheckLikes(){
            if (this.$store.state.loggedIn) {
                db.collection('users').doc(this.$store.state.userData.userSlug).get().then((doc) => {
                    const likes = doc.data().liked
                    for (var i = 0; i < likes.length; i++) {
                        if (likes[i].objectID === this.id) {
                            this.hearted = true
                            break
                        } else {
                            this.hearted = false
                        }
                    }
                    this.ready = true
                })
            }
        }
    },
    mounted() {
        setTimeout(() => this.CheckLikes(), 1000)
    },
    computed: {
        username() {
            if (this.$store.state.loggedIn) {
                return this.$store.state.userData.username
            } else {
                return null
            }
        }
    }
}
</script>

<style scoped>
.heart-button {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.heart-button a {
    border-bottom: 0px solid transparent;
    text-decoration: none;
    display: flex;
    align-items: center;
}
.heart-count {
    font-size: 10px;
    font-family: 'Roboto Mono';
    margin-left: 4px;
}
.material-icons {
    transform: rotateX(180);
}
.hearted {
    color: #6aacff;
}
</style>