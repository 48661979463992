<template>
    <div class="update-notification">
        <h2>New version available</h2>
        <p>Please update to continue.</p>
        <br/>
        <div @click="$emit('update')" class="cta-button">Update app</div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.update-notification {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 999;
    background-color: #000000f7;
    padding: 40px;
    text-align: left;
    box-sizing: border-box;
}
.version {
    font-size: 11px;
    opacity: 0.7;
}
.updates-list {
    text-align: left;
}
.list-header {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-size: 11px;
    margin-bottom: 6px;
    padding: 0px 6px;
    border-radius: 2px;
    height: fit-content;
    width: fit-content;
}
.list-header.added {
    background-color:indigo;
}
.list-header.fixed {
    background-color: darkolivegreen;
}
ul.list-unstyled {
    padding: 0;
    margin-top: 0px;
}
ul.list-unstyled li{
    list-style: none;
}
@media screen and (min-width: 900px) {
    .update-notification .button-group {
        max-width: 300px;
    }
}
</style>