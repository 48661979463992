import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import firebase from 'firebase/compat/app'
import '@firebase/firestore-compat'
import './registerServiceWorker'
import panZoom from 'vue-panzoom'
import Vue3TouchEvents from "vue3-touch-events"
import moment from 'moment'
import Vuex from 'vuex'
import { createStore } from 'vuex'
import VueCryptojs from 'vue-cryptojs'
import VueGtag from "vue-gtag"
import MasonryWall from '@yeger/vue-masonry-wall'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCFDYKHeypbem5lDqAnc8YLAFqqNvkvLs8",
    authDomain: "metitate-19ccf.firebaseapp.com",
    databaseURL: "https://metitate-19ccf-default-rtdb.firebaseio.com",
    projectId: "metitate-19ccf",
    storageBucket: "metitate-19ccf.appspot.com",
    messagingSenderId: "658228630780",
    appId: "1:658228630780:web:ef740e64cbce2de8333379",
    measurementId: "G-HDW77SHJKS"
};
// firebase.initializeApp(firebaseConfig)
const firebaseApp = firebase.initializeApp(firebaseConfig)
export default firebaseApp.firestore()

// Create a new store instance.
const store = createStore({
  state () {
    return {
      interval: 6,
      flavorTextAdjectives: [],
      flavorText: null,
      schedule: null,
      tabFocus: true,
      seconds: 0,
      isMobile: false,
      currentDepts: null,
      detailsOpen: false,
      mode: 'collector',
      modes: ['collector', 'omni', 'wander', 'department'],
      status: {
        message: '',
        ready: false,
        type: ''
      },
      objects: {
        count: 0,
        IDs: null
      },
      loggedIn: false,
      userData: {
      },
      collectionReference: [],
      lastSeenTemp: null,
      lastSeenURL: null,
      descriptionWords: [
        "flowing", "delicate", "simple", "bold", "fine", "subtle", "contrasting", "muted", "flat", "light", "dark", "dramatic", "shadowy", "rough", "smooth", "coarse", "silky", "shiny", "organic", "curvaceous", "circular", "geometric", "angular", "dynamic", "large", "small", "intimate", "miniature", "monumental", "massive", "grand", "vast", "useful", "colorful"
      ],
      collectionAreas: [
        {
          id: 1,
          name: 'American Decorative Arts',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/american-wing/the-american-wing_marquee.jpg',
          description: 'The American Wing\'s ever-evolving collection comprises some 20,000 works of art by African American, Euro American, Latin American, and Native American men and women. Ranging from the colonial to early-modern periods, the holdings include painting, sculpture, works on paper, and decorative arts—including furniture, textiles, ceramics, glass, silver, metalwork, jewelry, basketry, quill and bead embroidery—as well as historical interiors and architectural fragments.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/the-american-wing',
          minScore: 8999999
        },
        {
          id: 3,
          name: 'Ancient Near Eastern Art',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/ancient-near-east/ane-zodiac/2018/lion-marquee_2320x920.jpg',
          description: 'The Museum\'s collection of ancient Near Eastern art includes more than 7,000 works ranging in date from the eighth millennium B.C. through the centuries just beyond the time of the Arab conquests of the seventh century A.D. Objects come from a vast region centered in Mesopotamia, between the Tigris and Euphrates rivers, and extending north to the Caucasus and the Eurasian steppes and south to the Arabian peninsula. To the west the region includes Anatolia, Syria, and the Levant, bordered by the Mediterranean; to the east, it extends through Iran and western Central Asia, with connections as far as the Indus River Valley.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/ancient-near-eastern-art',
          minScore: 4999999
        },
        {
          id: 4,
          name: 'Arms and Armor',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/arms-and-armor/arms-and-armor_marquee.jpg',
          description: 'The principal goals of the Arms and Armor Department are to collect, preserve, research, publish, and exhibit distinguished examples representing the art of the armorer, swordsmith, and gunmaker. Arms and armor have been a vital part of virtually all cultures for thousands of years, pivotal not only in conquest and defense, but also in court pageantry and ceremonial events. Throughout time the best armor and weapons have represented the highest artistic and technical capabilities of the society and period in which they were made, forming a unique aspect of both art history and material culture.\n\nThe Met collection of arms and armor is a modern one, formed through the activities and interests of curators, trustees, private collectors, and donors over the past 125 years. The collection comprises approximately fourteen thousand objects, of which more than five thousand are European, two thousand are from the Near East, and four thousand from the Far East. It is one of the most comprehensive and encyclopedic collections of its kind.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/arms-and-armor',
          minScore: 4999999
        },
        {
          id: 5,
          name: 'Arts of Africa, Oceania, and the Americas',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/sub-saharan-africa/art_of_sub-saharan_africa_marquee_2320x920.jpg',
          description: 'The Met\'s collection of art of the peoples of sub-Saharan Africa, the Pacific Islands, and North, Central, and South America comprises more than eleven thousand works of art of varied materials and types, representing diverse cultural traditions from as early as 3000 B.C.E. to the present. Highlights include decorative and ceremonial objects from the Court of Benin in Nigeria; sculpture from West and Central Africa; images of gods, ancestors, and spirits from New Guinea, Island Melanesia, Polynesia, and Island Southeast Asia; and objects of gold, ceramic, and stone from the pre-Columbian cultures of Mexico and Central and South America.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/the-michael-c-rockefeller-wing',
          minScore: 999999
        },
        {
          id: 6,
          name: 'Asian Art',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/asian-art/asian_art_marquee_2320x940.jpg',
          description: 'The Met\'s collection of Asian art—more than 35,000 objects, ranging in date from the third millennium B.C. to the twenty-first century—is one of the largest and most comprehensive in the world. Each of the many civilizations of Asia is represented by outstanding works, providing an unrivaled experience of the artistic traditions of nearly half the world.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/asian-art',
          minScore: 4999999
        },
        {
          id: 7,
          name: 'The Cloisters',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/medieval-art/cloisters-primer/met-cloisters-primer-teaser-1520x720-resized.jpg',
          description: 'The Treasury contains small-scale works of exceptional splendor. On display is a richly carved English ivory cross of the twelfth century, as well as the Hours of Jeanne d\'Evreux, a masterpiece in miniature by the illuminator Jean Pucelle, and a book of hours created for the great medieval book collector, Jean of France, Duke of Berry. A gallery devoted to late medieval private devotion presents the celebrated Merode Triptych by the Netherlandish master Robert Campin. The Late Gothic Hall exhibits many of the finest fifteenth- and sixteenth-century works in the collection, including sculptures by Tilman Riemenschneider and altarpieces from Spain. Particularly beloved is the gallery that features the seven tapestries showing The Hunt of the Unicorn. Throughout The Met Cloisters are exceptional examples of stained-glass windows, including those from the castle chapel at Ebreichsdorf, Austria, and the Carmelite church at Boppard-am-Rhein.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/medieval-art-and-the-cloisters',
          minScore: 4999999
        },
        {
          id: 8,
          name: 'The Costume Institute',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/costume-institute/zodiac-page/ci_marquee_mcqueen_2320x940.jpg',
          description: 'The Costume Institute\'s collection of more than thirty-three thousand objects represents seven centuries of fashionable dress and accessories for men, women, and children, from the fifteenth century to the present.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/the-costume-institute',
          minScore: 999999
        },
        {
          id: 9,
          name: 'Drawings and Prints',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/drawings-and-prints/zodiac-department-page/marquee_2320.jpg',
          description: 'The Department’s vast collection of works on paper comprises approximately 21,000 drawings, 1.2 million prints, and 12,000 illustrated books created in Europe and the Americas from about 1400 to the present day. Since its foundation in 1916, the Department has been committed to collecting a wide range of works on paper, which includes both pieces that are incredibly rare and lauded for their aesthetic appeal, as well as material that is more popular, functional, and ephemeral. The broad scope of the department’s collecting encourages questions of connoisseurship as well as those pertaining to function and context, and demonstrates the vital role that prints, drawings, and illustrated books have played throughout history.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/drawings-and-prints',
          minScore: 499999
        },
        {
          id: 10,
          name: 'Egyptian Art',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/egyptian/egyptian_art_marquee.jpg',
          description: 'The Met collection of ancient Egyptian art consists of approximately 26,000 objects of artistic, historical, and cultural importance, dating from the Paleolithic to the Roman period (ca. 300,000 B.C.–A.D. 4th century). More than half of the collection is derived from the Museum\'s thirty-five years of archaeological work in Egypt, initiated in 1906 in response to increasing Western interest in the culture of ancient Egypt.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/egyptian-art',
          minScore: 14999999
        },
        {
          id: 11,
          name: 'European Paintings',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/european-paintings/zodiac-department-page/marquee_sophocles.jpg',
          description: 'The Met\'s celebrated European Paintings collection encompasses more than 2,500 works of art from the thirteenth through the early twentieth century. In addition to the department\'s galleries, pictures hang in the Robert Lehman Collection, the Jack and Belle Linsky Collection, and in other departmental galleries at The Met Fifth Avenue, as well as at The Met Cloisters.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/european-paintings',
          minScore: 499999
        },
        {
          id: 12,
          name: 'European Sculpture and Decorative Arts',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/esda/esda-marquee.jpg',
          description: 'The fifty thousand objects in the Museum\'s comprehensive and historically important collection of European sculpture and decorative arts reflect the development of a number of art forms in Western European countries from the early fifteenth through the early twentieth century. The holdings include sculpture in many sizes and media, woodwork and furniture, ceramics and glass, metalwork and jewelry, horological and mathematical instruments, and tapestries and textiles. Ceramics made in Asia for export to European markets and sculpture and decorative arts produced in Latin America during this period are also included among these works.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/european-sculpture-and-decorative-arts',
          minScore: 4999999
        },
        {
          id: 13,
          name: 'Greek and Roman Art',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/greek-and-roman/greek_roman_marquee_2320x940.jpg',
          description: 'The Greek and Roman galleries reveal classical art in all of its complexity and resonance. The objects range from small, engraved gemstones to black-figure and red-figure painted vases to over-lifesize statues and reflect virtually all of the materials in which ancient artists and craftsmen worked: marble, limestone, terracotta, bronze, gold, silver, and glass, as well as such rarer substances as ivory and bone, iron, lead, amber, and wood. The strengths of the collection include painted Greek vases, Greek grave reliefs, Cypriot sculpture, marble and bronze Roman portrait busts, and wall paintings from two villas on the slopes of Mount Vesuvius, one at Boscoreale and the other at Boscotrecase. The department\'s holdings in glass and silver are among the most important in the world, and the collection of archaic Attic sculpture is second only to that in Athens.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/greek-and-roman-art',
          minScore: 9999999
        },
        {
          id: 14,
          name: 'Islamic Art',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/islamic/islamic-home-page/landing-page-teasers/collections_teaser_02_1520x720.jpg',
          description: 'The Met\'s collection of Islamic art ranges in date from the seventh to the twenty-first century. Its more than 15,000 objects reflect the great diversity and range of the cultural traditions of Islam, with works from as far westward as Spain and Morocco and as far eastward as Central Asia and Indonesia. Comprising both sacred and secular objects, the collection reveals the mutual influence of artistic practices such as calligraphy, and the exchange of motifs such as vegetal ornament (the arabesque) and geometric patterning in both realms.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/islamic-art',
          minScore: 4999999
        },
        {
          id: 15,
          name: 'The Robert Lehman Collection',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/robert-lehman/about_the_met_curatorial_lehman_xlmarquee.jpg',
          description: 'The Robert Lehman Collection is one of the most distinguished privately assembled art collections in the United States. Robert Lehman\'s bequest to The Met, a collection of extraordinary quality and breadth acquired over the course of sixty years, is a remarkable example of twentieth-century American collecting. Spanning seven hundred years of western European art, from the fourteenth to the twentieth centuries, the 2,600 works include paintings, drawings, manuscript illumination, sculpture, glass, textiles, antique frames, maiolica, enamels, and precious jeweled objects.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/the-robert-lehman-collection',
          minScore: 4999999
        },
        {
          id: 16,
          name: 'The Libraries',
          previewImage: 'https://www.metmuseum.org/-/media/images/art/libraries-and-research-centers/digital-collections/digital_collections_marquee.jpg',
          description: 'With over one million volumes, an extensive digital collection, and online resources, Thomas J. Watson Library is one of the world\'s most comprehensive art libraries.',
          url: 'https://www.metmuseum.org/art/libraries-and-research-centers',
          minScore: 4999999
        },
        {
          id: 17,
          name: 'Medieval Art',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/medieval-art/medieval-zodiac/marquee.jpg',
          description: 'The Museum\'s collection of medieval and Byzantine art is among the most comprehensive in the world. Displayed in both The Met Fifth Avenue and in the Museum\'s branch in northern Manhattan, The Met Cloisters, the collection encompasses the art of the Mediterranean and Europe from the fall of Rome in the fourth century to the beginning of the Renaissance in the early sixteenth century. It also includes pre-medieval European works of art created during the Bronze Age and early Iron Age.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/medieval-art-and-the-cloisters',
          minScore: 4999999
        },
        {
          id: 18,
          name: 'Musical Instruments',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/musical-instruments/milp_banner_1520x720.jpg',
          description: 'The Museum\'s collection of musical instruments includes approximately five thousand examples from six continents and the Pacific Islands, dating from about 300 B.C. to the present. It illustrates the development of musical instruments from all cultures and eras. Selected for their technical and social importance as well as for their tonal and visual beauty, the instruments may be understood in a number of ways: as art objects, as ethnographic record, and as documents of the history of music and performance.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/musical-instruments',
          minScore: 4999999
        },
        {
          id: 19,
          name: 'Photographs',
          previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/photographs/photographs_marquee.jpg',
          description: 'Established as an independent curatorial department in 1992, The Met\'s Department of Photographs houses a collection of more than seventy-five thousand works spanning the history of photography from its invention in the 1830s to the present. Among the treasures from the early years of the medium are a rare album of photographs by William Henry Fox Talbot made just months after he presented his invention to the public; a large collection of portrait daguerreotypes by the Boston firm of Southworth and Hawes; landscape photographs of the American West by Timothy O\'Sullivan and Carleton Watkins; and fine examples of French photography from the 1850s by Edouard Baldus, Charles Nègre, Gustave Le Gray, Henri Le Secq, Nadar, and others.',
          url: 'https://www.metmuseum.org/about-the-met/collection-areas/photographs',
          minScore: 99999
        }
        // {
        //   id: 21,
        //   name: 'Modern Art',
        //   previewImage: 'https://www.metmuseum.org/-/media/images/about-the-met/collection-areas/modern/modcon_marquee_2020_2320x940.jpg',
        //   description: 'The Modern and Contemporary Art department at The Met is devoted to the study, collection and exhibition of art from 1890 to the present. An era marked by seismic cultural, social and political shifts across the globe, artistic responses to these changes have shaped multiple modernities and diverse contemporary practices. The department is committed to foregrounding the interconnected and richly variegated narratives and experiences that inform the art of this period from around the world.',
        //   url: 'https://www.metmuseum.org/about-the-met/collection-areas/modern-and-contemporary-art',
        //   minScore: 99999
        // }
      ],
      tips: [
        'When you add an object to your personal collection, no one else can collect it until you give it up.','You can only collect 10 objects at a time, so choose wisely.','If you create an account, you can save objects to your personal collection and view them again. Click the briefcase icon to view your collection.','You are a work of art.', 'You can tap and hold on an object to pause the timer.', 'Tap "Details" to see more images and information about a piece.', 'Metitate keeps track of how many objects you\'ve seen and examined.', 'You can tap an image in the detail view to zoom in.'
      ],
      famousArtists: [
        'Vincent van Gogh', 'Pablo Picasso', 'Jackson Pollock', 'Piet Mondrian', 'Mark Rothko', 'Gustave Courbet', 'Titian', 'Mary Cassatt', 'Johannes Vermeer', 'Fra Filippo Lippi', 'Caravaggio', 'Claude Monet', 'Edgar Degas', 'Winslow Homer', 'Jacques-Louis David', 'Emanuel Leutze', 'Elisabeth Louise Vigée Le Brun', 'Edouard Manet', 'Goya', 'John Singer Sargent', 'Camille Pissarro', 'Paul Cézanne', 'Rembrandt van Rijn', 'Rembrandt', 'Rembrandt (Rembrandt van Rijn)', 'Peter Paul Rubens', 'Leonardo da Vinci', 'Auguste Rodin', 'Henri de Toulouse-Lautrec', 'Georges Seurat', 'Paul Gauguin', 'Camille Corot', 'Gong Xian', 'Edvard Munch', 'George Bellows', 'Adélaïde Labille-Guiard', 'Marie Victoire Lemoine', 'Marie Denise Villers', 'Pieter Bruegel the Elder', 'Hans Holbein the Younger', 'Katsushika Hokusai', 'Auguste Renoir', 'Albrecht Dürer', 'James McNeill Whistler', 'El Greco (Domenikos Theotokopoulos)', 'El Greco', 'Velázquez (Diego Rodríguez de Silva y Velázquez)', 'Velázquez', 'Botticelli (Alessandro di Mariano Filipepi)', 'Botticelli', 'Gustav Klimt', 'James McNeill Whistler', 'Walker Evans'
      ],
      materials: {
        common: [
          'wool', 'lace', 'drawnwork', 'etching', 'aquatint', 'chalk', 'watercolor', 'gouache', 'steel', 'woodcut', 'terracotta', 'stucco', 'iron', 'copper', 'graphite', 'albumen photograph', 'pottery', 'lithograph', 'engraving', 'print', 'paper', 'ceramic', 'oil on canvas', 'yew', 'burl', 'pine'
        ],
        uncommon: [
          'steatite', 'silk', 'albumen silver print', 'porcelain', 'gold leaf', 'satinwood', 'carnelian', 'salted paper print', 'red oxide', 'marble', 'very fine', 'amethystine', 'mother of pearl', 'mother-of-pearl', 'pearl', 'agate', 'bronze', 'rhyolite', 'tortoiseshell', 'coral', 'gilded', 'silvered', 'gilt silver', 'shell', 'purplewood', 'gilt bronze', 'mahogany', 'silver', 'crystal', 'cupreous metal', 'precious metal', 'bone', 'indurated', 'diorite', 'plum', 'ebony', 'reptile skin', 'bloodwood', 'staghorn', 'semiprecious'
        ],
        rare: [
          'gold', 'jade', 'nephrite', 'jadeite', 'onyx', 'sapphire', 'lapis lazuli', 'coral', 'turquoise', 'obsidian', 'ivory', 'snake skin', 'rubies', 'ruby', 'garnets'
        ],
        epic: [
          'diamond', 'emerald', 'mercury'
        ],
        legendary: [
          'human femur', 'bread', 'organic material', 'dog hair'
        ]
      },
      falseMaterials: [
        'ivory laid'
      ],
      massProducedWords: [
        'etching', 'print', 'sketch', 'engraving', 'woodcut', 'aquatint', 'linocut', 'monotype', 'lithograph', 'lithography'
      ],
      departments: {
        TheAmericanWing: {
          min: 1,
          max: 20620
        },
        ArmsAndArmor: {
          min: 21810,
          max: 35368
        },
        AsianArt: {
          min: 35966,
          max: 77344
        }
      },
      unknownArtistWords: [
        'unidentified', 'unknown', 'painter', 'imprimerie de testu', 'L\'Imprimerie Royale', 'master of the die', 'anonymous', 'school', 'company', 'group', 'school of', 'chinese', 'manufactory', 'factory'
      ],
      obStep: 0,
      onboarding: {
        0: {
          headline: 'Welcome',
          body: 'Metitate is an art discovery and collecting app for the Metropolitan Museum of Art. Learn the basics of Metitate in this short walkthrough.',
          arrowPos: {
            mobile: {
              left: '17px'
            }
          },
          pos: '0px'
        },
        1: {
          headline: 'Slow down. Look at art.',
          body: 'Relax and enjoy a steady stream of art. Every five seconds, Metitate will search the depths of the Met collection for the most interesting objects. This curation algorithm uses several factors such as age, materials, and artist to determine what is most interesting.',
          arrowPos: {
            mobile: {
              left: '17px'
            }
          },
          pos: '0px'
        },
        2: {
          headline: 'Daily Collections',
          body: 'From European Paintings to Ancient Near Eastern Art, settle into a new collection every day. Every Sunday at 12AM (US CT), Metitate generates a random schedule of collections for the week. Everyone sees the same collection at the same time.',
          arrowPos: {
            mobile: {
              left: '211px'
            }
          },
          pos: '114px'
        },
        3: {
          headline: 'Collect Art',
          body: 'Discover valuable art and claim it as your own by adding it to your personal collection. When you collect a piece, no one else in the world can collect it... until you release it. But be selective - you can only hold 10 pieces in your collection at a time!',
          arrowPos: {
            mobile: {
              left: '271px'
            }
          },
          pos: '234px'
        },
        4: {
          headline: 'Thank you',
          body: 'This is a personal project and is not affiliated with the Met. I wanted to create an experience that simulates the way I love to view the Met collection - slightly curated, but mostly random. Thank you to the Met’s Open Access team for the API that powers this app. Thank you to my friends (Michael, Ege, DL, James, and Ellie) for helping me build the algorithm. And thank you to my wife, Yolanda, for being my first power user, my beta tester, my inspiration, and my support.',
          arrowPos: {
            mobile: {
              left: '17px'
            }
          },
          pos: '0px'
        },
      },
      GetObjectValue: function(piece){
        let scoreObj = {
          stats: {
            ageBonus: {
              displayName: 'Age bonus',
              show: true,
              bonus: 0,
              multiplier: 1000,
              age: 0
            },
            renownedArtist: {
              displayName: 'Renowned artist',
              show: false,
              bonus: 200000,
              multiplier: 1,
            },
            namedArtist: {
              displayName: 'Named artist',
              show: false,
              bonus: 0,
              multiplier: 5,
            },
            highlight: {
              displayName: 'Met highlight',
              show: false,
              bonus: 0,
              multiplier: 100,
            },
            onView: {
              displayName: 'On view',
              show: false,
              bonus: 0,
              multiplier: 2,
            },
            culturalArtifact: {
              displayName: 'Cultural artifact',
              show: false,
              bonus: 0,
              multiplier: 2,
            },
            additionalImages: {
              displayName: 'Additional images',
              show: false,
              bonus: 0,
              multiplier: 0,
            },
            views: {
              displayName: 'Views',
              show: false,
              bonus: 0,
              multiplier: 0,
            },
            fragment: {
              displayName: 'Fragment',
              show: false,
              bonus: 0,
              multiplier: 0.5,
            },
            reproduction: {
              displayName: 'Reproduction',
              show: false,
              bonus: 0,
              multiplier: 0.75,
            }
          },
          materials: [
            {
              displayName: 'Common materials',
              list: [],
              bonus: 0,
              multiplier: 1
            },
            {
              displayName: 'Uncommon materials',
              list: [],
              bonus: 0,
              multiplier: 2
            },
            {
              displayName: 'Rare materials',
              list: [],
              bonus: 0,
              multiplier: 3
            },
            {
              displayName: 'Epic materials',
              list: [],
              bonus: 0,
              multiplier: 4
            },
            {
              displayName: 'Legendary materials',
              list: [],
              bonus: 0,
              multiplier: 5
            },
          ],
          score: 0,
          prettyScore: ''
        }

        const today = new Date()
        const currentYear = today.getFullYear()
        let localScore = 0

        // Age multiplier: (1000*age)/3
        // where 3 is the number of thousands of years you want to be equal to 1MM
        // to do: filter out facsimiles
        scoreObj.stats.ageBonus.age = (currentYear - piece.objectEndDate)
        if (scoreObj.stats.ageBonus.age === 0) {
          scoreObj.stats.ageBonus.age = 1
          console.log('zero age')
        }
        scoreObj.stats.ageBonus.age = Math.round((scoreObj.stats.ageBonus.multiplier * scoreObj.stats.ageBonus.age)/3)
        localScore = scoreObj.stats.ageBonus.age

        // Famous Artist bonus: 5,000,000
        for (const name of this.famousArtists){
            if (piece.artistDisplayName === name) {
                localScore = localScore + scoreObj.stats.renownedArtist.bonus
                scoreObj.stats.renownedArtist.show = true
            }
        }

        // Check list of catch words for unidentified artist
        var isUnknownArtist = false
        for(const word of this.unknownArtistWords) {
          if (piece.artistDisplayName.toLowerCase().includes(word)) {
            isUnknownArtist = true
          }
        }
        if(!isUnknownArtist && piece.artistDisplayName !== '') {
          localScore = localScore * scoreObj.stats.namedArtist.multiplier
          scoreObj.stats.namedArtist.show = true
        }

        // Is Highlight multiplier: 100
        if(piece.isHighlight) {
          localScore = localScore * scoreObj.stats.highlight.multiplier
          scoreObj.stats.highlight.show = true
        }

        //Is on view multiplier: 5
        if(piece.GalleryNumber !== '') {
          localScore = localScore * scoreObj.stats.onView.multiplier
          scoreObj.stats.onView.show = true
        }

        //Is known culture multiplier: 1.5
        if(piece.culture !== '') {
          localScore = localScore * scoreObj.stats.culturalArtifact.multiplier
          scoreObj.stats.culturalArtifact.show = true
      }

        // Additional Images multiplier: number of addtional images
        if (piece.additionalImages.length > 0) {
          scoreObj.stats.additionalImages.multiplier = piece.additionalImages.length + 1
          localScore = localScore * scoreObj.stats.additionalImages.multiplier
          scoreObj.stats.additionalImages.show = true
        }

        // Views multiplier: number of views
        // if (views > 0) {
        //   scoreObj.stats.views.multiplier = views
        //   localScore = localScore * scoreObj.stats.views.multiplier
        //   scoreObj.stats.views.show = true
        // }

        // COMMON Material multplier: 1
        // for (const material of this.materials.common){
        //   if (piece.medium.toLowerCase().includes(material)) {
        //     localScore = localScore * scoreObj.materials[0].multiplier
        //     scoreObj.materials[0].list.push(material)
        //     //console.log('Common material')
        //   }
        // }

        // UNCOMMON Material multplier: 2
        for (const material of this.materials.uncommon){
          if (this.parseMedium(piece.medium).indexOf(material) !== -1) {
            localScore = localScore * scoreObj.materials[1].multiplier
            scoreObj.materials[1].list.push(material)
            //console.log('Uncommon material')
          }
        }

        // RARE Material multplier: 3
        for (const material of this.materials.rare){
          if (piece.medium.toLowerCase().includes(material)) {
            localScore = localScore * scoreObj.materials[2].multiplier
            scoreObj.materials[2].list.push(material)
            //console.log('Rare material')
          }
        }

        // EPIC Material multplier: 4
        for (const material of this.materials.epic){
          if (this.parseMedium(piece.medium).indexOf(material) !== -1) {
            localScore = localScore * scoreObj.materials[3].multiplier
            scoreObj.materials[3].list.push(material)
            //console.log('Epic material')
          }
        }

        // LEGENDARY Material multplier: 5
        for (const material of this.materials.legendary){
          if (this.parseMedium(piece.medium).indexOf(material) !== -1) {
            localScore = localScore * scoreObj.materials[4].multiplier
            scoreObj.materials[4].list.push(material)
            //console.log('Legendary material')
          }
        }

        // Fragment multiplier: 0.05
        if (piece.title.toLowerCase().includes('fragment')) {
          localScore = localScore * scoreObj.stats.fragment.multiplier
          scoreObj.stats.fragment.show = true
        }

        // check for drawing or print
        if (piece.classification === 'Prints') {
          localScore = localScore * scoreObj.stats.reproduction.multiplier
          scoreObj.stats.reproduction.show = true
        }

        scoreObj.score = Math.round(localScore)
        // scoreObj.prettyScore = scoreObj.score.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        scoreObj.prettyScore = scoreObj.score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return scoreObj
      },
      GetCollectionScore(collection) {
        var collectionScore = 0
        for(var i = 0; i < collection.length; i++) {
          collectionScore = collectionScore + this.GetObjectValue(collection[i]).score
        }
        return collectionScore
      },
      GetRandomTip() {
          return this.tips[Math.floor(Math.random() * this.tips.length)]
      },
      PrettyPrintNumber(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      GetCountdown(){
        var now = new Date();
        var night = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + 1, // the next day, ...
            0, 0, 0 // ...at 00:00:00 hours
        );
        var msTillMidnight = night.getTime() - now.getTime();
        return msTillMidnight
      },
      msToTime(s) {
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;

        return hrs + ' hours and ' + mins + ' minutes';
      },
      handleDate(year) {
        if(year <= 0) {
            var plainYear = Math.abs(year)
            return plainYear + " BC"
        } else if(year <= 1000) {
            return year + " AD"
        } else {
            return year
        }
      },
      handleArtistName(name) {
          if(name == '') {
              return 'Unknown'
          } else {
              return name
          }
      },
      handleTitle(title){
          if(title && title.includes('<i>')) {
              var cleanTitle = title.replace('<i>', '')
              cleanTitle = cleanTitle.replace('</i>', '')
              return cleanTitle
          } else {
              return title
          }
      },
      parseMedium(str) {
        var noSpaces = str.replace(/ /g, ',')
        return noSpaces.toLowerCase().split(',')
      }
    }
  },
  getters: {
    randomTip (state) {
      return state.tips[Math.floor(Math.random() * state.tips.length)]
    }
  },
  mutations: {
    updateLastSeen (state, payload) {
      state.userData.lastSeenURL = payload.URL
      state.userData.lastSeen = payload.obj
    },
    updateStatus (state, payload) {
      state.status.message = payload.message
      state.status.ready = payload.ready,
      state.status.type = payload.type,
      state.status.mod = payload.mod
    },
    addToUserCollection (state, payload) {
      state.userData.collected.push(payload.object)
    },
    addToUserLikes (state, payload) {
      state.userData.liked.push(payload.object)
    },
    removeFromUserCollection (state, payload) {
      state.userData.collected.splice(payload.index, 1)
    },
    removeFromUserLikes (state, payload) {
      state.userData.liked.splice(payload.index, 1)
    },
    logIn (state, payload) {
      state.loggedIn = payload.loggedIn
      state.userData = payload.userData

      // if(state.loggedIn) {
      //   console.log('Signed in as:', state.userData.username)
      // } else {
      //   console.log('not signed in')
      // }
    },
    getObjects(state, payload) {
      state.objects.count = payload.amount
      state.objects.IDs = payload.IDs
    },
    changeMode(state, payload) {
      state.mode = payload.mode
    },
    openDetails(state, payload) {
      state.detailsOpen = payload.isOpen
    },
    setCurrentDepts(state, payload) {
      state.currentDepts = {
        name: payload.name,
        objectCount: payload.objectCount,
        previewImage: payload.previewImage,
        description: payload.description,
        url: payload.url,
        minScore: payload.minScore
      }
    },
    detectMobile(state, payload) {
      state.isMobile = payload.isMobile
    },
    updateTimer(state, payload) {
      state.seconds = payload.seconds
    },
    updateTabFocus(state, payload) {
      state.tabFocus = payload.tabFocus
    },
    getSchedule(state, payload) {
      state.schedule = payload.schedule
    },
    lastSeenTemp(state, payload) {
      state.lastSeenTemp = payload.obj
    },
    flavorTextAdjectives(state, payload) {
      state.flavorTextAdjectives = payload.adjectives
    },
    flavorText(state, payload) {
      state.flavorText = payload.flavorText
    },
    toggleAlgo(state, payload) {
      state.userData.preferences.algo = payload.algo
    },
    createPlaylistPref(state, payload) {
      state.userData.preferences.playlist = {
        active: payload.active,
        list: payload.list,
        schedule: {
          collections: payload.collections,
          dateCreated: payload.dateCreated
        }
      }
    },
    togglePlaylist(state, payload) {
      state.userData.preferences.playlist.active = payload.active
    },
    updatePlaylist(state, payload) {
      state.userData.preferences.playlist.list = payload.list
    },
    nextStep (state) {
      state.obStep++
    },
    prevStep (state) {
      state.obStep--
    }
  }
})

export {store}

createApp(App).use(router).use(panZoom).use(Vue3TouchEvents).use(Vuex).use(store).use(moment).use(MasonryWall).use(VueCryptojs).use(VueGtag, {config: {id: "G-HDW77SHJKS"}}).mount('#app')