<template>
    <div v-if="this.$store.state.isMobile" class="tooltip" :style="{width: width}">
        <div @click.stop="dismiss" class="close-button"><span class="material-icons">close</span></div>
        <div class="tooltip-arrow"
        :style="{left: this.$store.state.onboarding[this.$store.state.obStep].arrowPos.mobile.left}">
            <span class="material-icons">arrow_drop_up</span>
        </div>
        <div class="tooltip-container">
            <h2>{{this.$store.state.onboarding[this.$store.state.obStep].headline}}</h2>
            <div class="copy">{{this.$store.state.onboarding[this.$store.state.obStep].body}}</div>
            <div class="button-group">
                <div @click.stop="close" v-if="this.$store.state.obStep === 0" class="button">Don't show again</div>
                <div @click.stop="prevStep" v-else class="button">Previous</div>
                <div v-if="this.$store.state.obStep === 4" @click.stop="close" class="button">Done</div>
                <div v-else @click.stop="nextStep" class="button">Next</div>
            </div>
        </div>
    </div>
    <div v-else class="tooltip" :style="{width: width, top: this.$store.state.onboarding[this.$store.state.obStep].pos}">
        <div @click.stop="dismiss" class="close-button"><span class="material-icons">close</span></div>
        <div class="tooltip-arrow"><span class="material-icons">arrow_drop_up</span></div>
        <div class="tooltip-container">
            <h2>{{this.$store.state.onboarding[this.$store.state.obStep].headline}}</h2>
            <div class="copy">{{this.$store.state.onboarding[this.$store.state.obStep].body}}</div>
            <div class="button-group">
                <div @click.stop="close" v-if="this.$store.state.obStep === 0" class="button">Don't show again</div>
                <div @click.stop="prevStep" v-else class="button">Previous</div>
                <div v-if="this.$store.state.obStep === 4" @click.stop="close" class="button">Done</div>
                <div v-else @click.stop="nextStep" class="button">Next</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Display',
    props: {

    },
    data() {
        return {
            width: null,
            showTooltips: null
        }
    },
    methods: {
        nextStep() {
            this.$store.commit('nextStep')
        },
        prevStep() {
            this.$store.commit('prevStep')
        },
        close() {
            this.$emit('close')
            localStorage.tutorialComplete = 'true'
        },
        dismiss() {
            this.$emit('dismiss')
        }
    },
    mounted () { 
        if (this.$store.state.isMobile) {
            this.width = (window.innerWidth - 20) + 'px'
        } else {
            this.width = '400px'
        }
    }
}
</script>

<style scoped>
.tooltip {
    position: absolute;
    top: 60px;
    left: 0;
    border-radius: 2px;
    background-color: #fff0e0;
    color: black;
}
h2 {
    margin-bottom: 10px;
}
.close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}
.tooltip-arrow {
    position: absolute;
    color: #fff0e0;
    top: -14px;
    left: initial;
    display: none;
}
.tooltip-container {
    padding: 20px;
}
.tooltip-container .copy {
    margin-bottom: 20px;
}
@media screen and (min-width: 900px) {
    .tooltip {
        left: 80px;
        top: 0;
    }
    .tooltip-arrow {
        left: -10px;
        top: 44px;
        transform: rotate(-90deg);
        display: block;
    }
}
</style>