<template>
    <div class="download-database">
        <div class="download-database-inner">
            <h2 style="margin-bottom: 20px;">Download Met database</h2>
            <select v-if="!downloading" style="margin-bottom: 20px;" v-model="selectedDepartment">
                <option v-for="department in this.$store.state.collectionAreas" :key="department.id" :value="department.id">
                    {{ department.name }}
                </option>
            </select>
            <div v-if="!downloading && this.selectedDepartment" style="margin-bottom: 20px;" class="button" @click="StartDownload()">Start download</div>
            <div v-if="downloading" style="margin-bottom: 20px;" class="download-status">
                <div class="square-rotate-2"  style="margin-right: 10px;"></div> Downloading: {{ findNameById(this.selectedDepartment) }}
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'; // Assuming axios is used for HTTP requests
import 'firebase/compat/auth'
// import db from '@/main'

export default {
  data() {
    return {
      dataStore: {}, // Local JSON object to store fetched data,
      selectedDepartment: null,
      downloading: false
    };
  },
  methods: {
    // Function to fetch data for a given ID and update the local dataStore
    async fetchDataForId(id) {
      if (this.cache[id]) {
        console.log(`Cache hit for ID: ${id}`);
        return this.cache[id];
      }

      try {
        const response = await axios.get(`https://collectionapi.metmuseum.org/public/collection/v1/objects/${id}`);
        this.cache[id] = response.data; // Save response in cache
        return response.data;
      } catch (error) {
        console.error(`Error fetching data for ID ${id}: `, error);
        return null;
      }
    },
    // Function to loop through IDs, fetch and save data for each
    async fetchAndSaveData(ids) {
      for (let id of ids) {
        const data = await this.fetchDataForId(id);
        if (data) {
          // Save fetched data to the local dataStore
          this.$set(this.dataStore, id, data);
        }
      }
    },
    async saveDataToFirestore() {
      try {
        // Assuming you have a 'dataCollection' in Firestore
        //const docRef = await db.collection('dataCollection').add(this.dataStore);
        //console.log('Document written with ID: ', docRef.id);
        console.log('simulated firestore write')
      } catch (error) {
        console.error('Error adding document: ', error);
      }
    },
    StartDownload() {
        this.downloading = true
        this.GetDeptIDs(this.selectedDepartment)
    },
    findNameById(id) {
        for (let item of this.$store.state.collectionAreas) {
            if (item.id === id) {
            return item.name;
            }
        }
        return null; // Return null if no matching id is found
    },
    GetDeptIDs(dept) {
        axios.get('https://collectionapi.metmuseum.org/public/collection/v1/objects?departmentIds=' + dept)
        .then((response) => {
            this.fetchAndSaveData(response.data.objectIDs).then(() => {
            // Once data is fetched and stored locally, save it to Firestore
                this.saveDataToFirestore();
            })
            console.log(response.data)
        })
        .finally(() => {
            console.log('finished')
        })
    }
  },
  created() {
  },
  beforeCreate() {
    this.cache = {}; // Initialize cache
  },
};
</script>

<style scoped lang="scss">
.download-database {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    position: absolute;
    z-index: 100;
    background-color: #2f1400;
    top: 0;
    left: 0;
    .download-database-inner {
        margin: 0 auto;
        width: 650px;
    }
    .download-status {
        display: flex;
        align-items: center;
    }
}
</style>