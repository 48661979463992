<template>
    <div class="explore">
        <div v-if="ready" ref="room" class="room">
            <!-- <div v-for="wall in map.elements.walls" :key="wall" class="tile wall" :style="wall.styleObject"></div> -->
            <!-- <div v-for="floor in map.elements.floor" :key="floor" class="tile floor" :style="floor.styleObject"></div> -->
            <div v-for="(row, index) in map.schematic" :key="index">
                <div v-for="tile in row[index]" :key="tile" class="tile" :style="tile.styleObject"></div>
            </div>
        </div>
        <div @click="BuildMap" class="toolbar">
            Regenerate
        </div>
    </div>
</template>

<script>
var Chance = require('chance');
var chance = new Chance();
export default {
    name: 'Explore',
    props: {
    },
    components: {
    },
    data() {
        return {
            map: {
                schematic: []
            },
            ready: true,
            mapSize: {
                height: 12,
                width: 6
            }
        }
    },
    computed: {
        
    },
    methods: {
        BuildMap(){
            this.map.elements = {
                walls: [],
                objects: [],
                guards: [],
                floor: []
            }
            this.BuildWalls()
        },
        BuildWalls(){
            // var prob
            // var built = 0
            // var total = 0
            //Built top and bottom walls
            // for (var ct = 1; ct <= 6; ct++) {
            //     if (built === 1) {
            //         prob = 100
            //     } else if (built <= 3) {
            //         //if not set probability relatively high
            //         prob = 18
            //     }
            //     if (chance.bool({likelihood: prob})) {
            //         this.map.walls.push({
            //             styleObject: {
            //                 gridArea: 1 + '/' + ct
            //             }
            //         })
            //         built++
            //     } else {
            //         built = 0
            //     }
            // }
            // for (var cb = 1; cb <= 6; cb++) {
            //     if (built === 1) {
            //         prob = 100
            //     } else if (built <= 3) {
            //         //if not set probability relatively high
            //         prob = 18
            //     }
            //     if (chance.bool({likelihood: prob})) {
            //         this.map.walls.push({
            //             styleObject: {
            //                 gridArea: 12 + '/' + cb
            //             }
            //         })
            //         built++
            //     } else {
            //         built = 0
            //     }
            // }

            // for (var r = 1; r <= 12; r++) {
            //     for (var c = 1; c <= 6; c++) {
            //         if (total < 10) {
            //             //If previous tile has wall, set probability to 100
            //             if (r === 1 || r === 12 || c < 3 || c > 4) {
            //                 if (built === 1) {
            //                     prob = 100
            //                 } else if (built <= 3) {
            //                     //if not set probability relatively high
            //                     prob = 18
            //                 }
            //             } else {
            //                 //else, set probability to 0
            //                 prob = 0
            //             }
            //             //use probability to determine if wall is built
            //             if (chance.bool({likelihood: prob})) {
            //                 total++
            //                 //if dice roll is true, build a wall tile
            //                 this.map.walls.push({
            //                     styleObject: {
            //                         gridArea: r + '/' + c
            //                     }
            //                 })
            //                 //tell generator that a wall was built
            //                 if (c !== 6) {
            //                     built++
            //                     console.log('a', built)
            //                 } else {
            //                     built = 0
            //                     console.log('b', built)
            //                 }
            //             } else {
            //                 built = 0
            //             }
            //         }
            //     }
            // }
            var prob = 5
            var wait = false
            var spacing = 0
            var wallSize = []
            for (var r = 1; r <= this.mapSize.height; r++) {
                for (var c = 1; c <= this.mapSize.width; c++) {
                    if (chance.bool({likelihood: prob}) && wait === false) {
                        var re = chance.integer({min: 1, max: 3})
                        var ce = chance.integer({min: 1, max: 3})
                        wallSize = {
                            height: re,
                            width: ce
                        }
                        console.log(wallSize)
                        this.addWall(r,c, r+re, c+ce, wallSize)
                        wait = true
                        // prob = 90
                        // if (chance.bool({likelihood: prob})) {
                        //     this.addWall(r, c+1)
                        //     //this.addWall(r+1, c+1)
                        //     this.addWall(r+1, c)
                        // }
                        // prob = 5
                    } else if(spacing < 25) {
                        spacing++
                        this.addFloor(r, c)
                    } else {
                        spacing = 0
                        wait = false
                        this.addFloor(r, c)
                    }
                }
            }
        },
        addWall(rs, cs, re, ce, wallSize) {
            this.map.elements.walls.push({
                styleObject: {
                    gridArea: rs + '/' + cs + '/' + re + '/' + ce,
                },
                dimensions: wallSize
            })
        },
        addFloor(r, c) {
            this.map.elements.floor.push({
                styleObject: {
                    gridArea: r + '/' + c,
                }
            })
        },
        updateSchematic(tileData){
            // var scheme = {
            //     rows: [
            //         [{
            //             col: 1,
            //             type: 'wall'
            //         }]
            //     ]
            // }
                for (var i = 0; i < tileData.length; i++) {
                    if (this.schematic.tileData[i]) {
                        console.log('tile data already logged')
                    } else {
                        this.map.schematic.push([tileData.row, tileData.col, tileData.type])
                    }
                }
            
            console.log(this.map.schematic)
        }
    },
    mounted() {
        //build emtpy matrix
        const rows = 12
        const cols = 6
        const floorplan = []
        for (let i = 0; i < rows; i++) {
            floorplan[i] = []
            for (let j = 0; j < cols; j++) {
                floorplan[i][j] = null
            }
        }
        for (let i = 0; i < rows; i++) {
            floorplan[i] = []
            for (let j = 0; j < cols; j++) {
                if (floorplan[i][j] === undefined) {
                    if (chance.bool({likelihood: 80})) {
                        floorplan[i][j] = {
                            type: 'floor',
                            piece: null,
                            expansion: false,
                            styleObject: {
                                gridArea: (i+1) + '/' + (j+1),
                                backgroundColor: '#CEC4C1'
                            }
                        }
                    } else {
                        var width = chance.integer({min: 1, max: 3})
                        var height = chance.integer({min: 1, max: 3})
                        //prevent growing beyond rows
                        if (i === 11) {
                            height = 0
                        } else if (i === 10 && height > 1) {
                            height = 1
                        } else if (i === 9 && height > 2) {
                            height = 2
                        }
                        //prevent growing beyond columns
                        if (j === 5) {
                            width = 0
                        } else if (j === 4 && width > 1) {
                            width = 1
                        } else if (j === 3 && width > 2) {
                            width = 2
                        }
                        floorplan[i][j] = {
                            type: 'wall',
                            piece: null,
                            expansion: false,
                            styleObject: {
                                gridArea: (i+1) + '/' + (j+1),
                                backgroundColor: '#f8f8f8'
                            }
                        }
                        // for (let w = 0; w < width; w++) {
                        //     floorplan[i][j + w] = {
                        //         type: 'wall',
                        //         piece: null,
                        //         expansion: true,
                        //         styleObject: {
                        //             gridArea: i + '/' + j,
                        //             backgroundColor: '#f8f8f8'
                        //         }
                        //     }
                        // }
                        // for (let h = 0; h < height; h++) {
                        //     floorplan[i + h][j] = {
                        //         type: 'wall',
                        //         piece: null,
                        //         expansion: true,
                        //         styleObject: {
                        //             gridArea: i + '/' + j,
                        //             backgroundColor: '#f8f8f8'
                        //         }
                        //     }
                        // }
                    }
                }
            }
            this.map.schematic.push(floorplan[i])
        }
        console.log(this.map.schematic)
        //this.BuildMap()
    }
}
</script>

<style>
.explore {
    display: flex;
    flex-direction: column;
    grid-area: 2/1/4/1;
    height: 100%;
}
.room {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 100%;
    background-color: #CEC4C1;
    overflow: clip;
    border-radius: 18px;
    border: 6px solid black;
    padding: 4px;
}
.room .tile {
    cursor: pointer;
    border: 2px solid #CEC4C1;
    box-sizing: border-box;
    border-radius: 8px;
}
.tile.wall {
    background-color: #f8f8f8;
    border-radius: 8px;
}
.tile.floor {
    background-color: #CEC4C1;
    border-radius: 8px;
}
.rombus-tile {
    background-color: #e6eef7;
opacity: 1;
background-image:  linear-gradient(135deg, #e1e7f1 25%, transparent 25%), linear-gradient(225deg, #e1e7f1 25%, transparent 25%), linear-gradient(45deg, #e1e7f1 25%, transparent 25%), linear-gradient(315deg, #e1e7f1 25%, #e6eef7 25%);
background-position:  35px 0, 35px 0, 0 0, 0 0;
background-size: 35px 35px;
background-repeat: repeat;
}
.wood-floor {
    background-color: #000000;
    background-image: url(https://www.transparenttextures.com/patterns/retina-wood.png);
    background-size: 70%;
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}
.toolbar {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>