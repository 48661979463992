<template>
    <div class="reaction-bar">
        <HeartButton v-if="showHeartButton"
        @heart-piece="$emit('heart-piece')"
        :id="piece.objectID"
        :showCount="false"
        :piece="piece" />
        <!-- <Describe
        :id="piece.objectID" 
        @describing="describing = $event"
        @describe-piece="$emit('describe-piece')"
        v-if="showDescribePrompt && this.$store.state.userData.username === 'brainnews'" /> -->
        <CardButton v-if="this.$store.state.flavorText && 2 == 1" :piece="piece" />
        <ShareButton v-if="!describing && this.$store.state.isMobile" :piece="piece" />
    </div>
</template>

<script>
import ShareButton from '@/components/ShareButton'
import HeartButton from '@/components/HeartButton'
// import Describe from '@/components/Describe'
import CardButton from '@/components/CardButton'
export default {
    name: 'ReactionBar',
    components: {
        ShareButton, HeartButton, CardButton
    },
    props: {
        piece: Object
    },
    data() {
        return {
            describing: false,
            showDescribePrompt: false,
            showHeartButton: true
        }
    }
}
</script>

<style scoped>
.reaction-bar {
    display: flex;
    padding: 20px 20px 0;
    align-items: center;
    font-size: 24px;
    justify-content: space-between;
}
@media screen and (min-width: 900px) {
    .reaction-bar {
        width: 900px;
        margin: 0 auto;
    }
}
</style>