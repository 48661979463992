<template>
<transition name="fade">
    <p v-if="owner && this.$store.state.loggedIn" class="owner-badge"><span class="material-icons">work</span> {{this.$store.state.userData.username === owner ? 'In your collection' : 'Owned by ' + owner}}</p>
    <p v-else-if="owner && !this.$store.state.loggedIn" class="owner-badge"><span class="material-icons">work</span> {{'Owned by ' + owner}}</p>
</transition>
</template>

<script>
export default {
    name: 'OwnerBadge',
    props: {
        owner: String
    },
    data() {
        return {
            
        }
    }

}
</script>

<style scoped>
.owner-badge {
    display: flex;
    align-items: center;
    color: #dd8aff;
}
.material-icons {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border-radius: 100%;
    margin-right: 6px;
}
</style>