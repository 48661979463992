<template>
    <div class="settings custom-scrollbar">
        <UserPreferences />
    </div>
</template>

<script>
import router from '../router/index.js'
import UserPreferences from '@/components/UserPreferences'
export default {
    name: 'Settings',
    components: {
        UserPreferences
    },
    methods: {
        goBack() {
            router.go(-1)
        }
    }
}
</script>

<style scoped>
.settings {
    background-color: black;
    grid-area: 2 / 1 / 4 / 1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    padding: 20px;
}
.settings>* {
    margin-bottom: 40px;
}
@media screen and (min-width: 900px){
    .settings {
        grid-area: 1 / 1 / 4 / 1;
        width: 440px;
        margin: 0 auto;
        padding-top: 40px;
    }
}
</style>