<template>
  <div class="wrapper">
    <Navbar :progress="progress"/>
    <router-view @tick="tick($event)" />
    <transition name="fade">
      <UpdateNotification v-if="isRefresh" @update="update" />
    </transition>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import Navbar from '@/components/Navbar'
import UpdateNotification from '@/components/UpdateNotification'
import db from '@/main'
export default {
  components: {
    Navbar, UpdateNotification
  },
  data() {
    return {
      progress: 0,
      windowHeight: null,
      refreshing: false,
      isRefresh: false,
      registration: null,
      updateExists: false,
      tabFocus: false,
      currentURL: null,
      deptsArray: this.$store.state.collectionAreas
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    tick(seconds) {
      this.progress = -Math.abs(40 - seconds * (40 / this.$store.state.interval))
    },
    update(){
        this.isRefresh = false;
        if (this.registration || this.registration.waiting) {
          this.registration.waiting.postMessage({type:'SKIP_WAITING'});
        }
    },
    appUpdateUI: function (e){
      this.registration = e.detail;
      this.isRefresh = true;
    },
    isDateMoreThanAWeekAgo(date) {
      const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000; // 7 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds

      // Calculate the time difference between the current date and the provided date
      const currentDate = new Date();
      const providedDate = new Date(date.toDate());
      const timeDifference = currentDate - providedDate;

      // Compare the time difference with one week in milliseconds
      return timeDifference > oneWeekInMilliseconds;
    },
    ShuffleCollections(playlist){
      var departments = playlist

      let galleries = []

      for (var i = 0; i < 7; i++) {
          var randomIndex = Math.floor(Math.random()*departments.length)
          let gallery = departments.splice(randomIndex, 1)
          galleries.push(gallery[0])
      }
      
      return galleries
    },
    LoadUserData(userID, username, data){
      if (data.preferences.playlist) {
        this.$store.commit('logIn', {
          loggedIn: true,
          userData: {
            userID: userID,
            username: username,
            userSlug: data.userSlug,
            collected: data.collected,
            liked: data.liked,
            examined: data.examined,
            seen: data.seen,
            lastSeen: data.lastSeen,
            preferences: {
              hideFragments: data.preferences.hideFragments,
              interval: data.preferences.interval,
              algo: data.preferences.algo,
              playlist: {
                active: data.preferences.playlist.active,
                list: data.preferences.playlist.list,
                schedule: {
                  collections: data.preferences.playlist.schedule.collections,
                  dateCreated: data.preferences.playlist.schedule.dateCreated
                }
              }
            }
          }
        })
      } else {
        this.$store.commit('logIn', {
          loggedIn: true,
          userData: {
            userID: userID,
            username: username,
            userSlug: data.userSlug,
            collected: data.collected,
            liked: data.liked,
            examined: data.examined,
            seen: data.seen,
            lastSeen: data.lastSeen,
            preferences: {
              hideFragments: data.preferences.hideFragments,
              interval: data.preferences.interval,
              algo: data.preferences.algo,
              playlist: {
                active: false,
                list: null,
                schedule: {
                  collections: null,
                  dateCreated: null
                }
              }
            }
          }
        })
      }
    },
  },
  beforeMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        console.log('not logged in')
        this.$store.commit('logIn', {
            loggedIn: false,
            userID: null
        })
      } else if (user) {
        if (this.$route.path == '/login' || this.$route.path == '/register') {
          this.$router.replace('/')
        }
        db.collection('users').where('user_id', '==', user.uid).get().then(snapshot => {
          snapshot.forEach((doc) => {
            if (doc.data().preferences.algo === undefined) {
              db.collection('users').doc(doc.data().userSlug).update({
                  "preferences.algo": true
              })
            }
            this.LoadUserData(user.uid, doc.id, doc.data())
          })
        })
      }
    })
  },
  mounted() {
    this.currentURL = window.location.pathname
    if(window.innerWidth < 900) {
      this.$store.commit('detectMobile', {
        isMobile: true
      })
    }
    window.addEventListener('resize', () => {
      this.windowHeight = window.innerHeight
    })
  },
  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('serviceWorkerUpdateEvent', this.appUpdateUI, { once: true })

    navigator.serviceWorker.addEventListener(
        'controllerchange', () => {
          if (this.refreshing) return;
          this.refreshing = true;
          window.location.reload();
        }
    )
  }
}
</script>

<style lang="scss">
@import "~vue-mobile-viewport-height/src/mobile-viewport-height.scss";
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Two+Tone');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,300;0,400;0,600;1,700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
html * {
  box-sizing: border-box;
}
#app, body {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  font-size: 14px;
  background-color: black;
  line-height: 26px;
}
p {
  line-height: 22px;
}
.wrapper {
  width: 100vw;
  @include mobile-viewport-height();
  margin: 0 auto;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.2fr 2.6fr 0.2fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
}
.text-light {
  font-weight: 400;
  color: #adadad;
}
.text-light-white {
  font-weight: 400;
  color: #fff;
}
.text-danger, a.text-danger {
  color: rgb(255, 0, 0);
  font-weight: normal;
}
.text-small {
  font-size: 12px;
}
.text-italic {
  font-style: italic;
}
.text-mono {
  font-family: 'Roboto Mono';
  font-size: 12px;
  text-transform: uppercase;
}
.button-group {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
.button {
  background-color: transparent;
  border: 1px solid #5f5f5f;
  padding: 2px 20px;
  border-radius: 4px;
  text-align: center;
  font-family: 'Roboto Mono', monospace;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-size: 11px;
  font-weight: 700;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s linear;
  width: fit-content;
}
.button-group .button {
  width: 100%;
}
.button:active {
  background-color: transparent;
}
.onboard-card .button-text-only:hover {
  background-color: transparent;
  text-decoration: underline;
}
.button-group .button:last-child {
    margin-right: 0px;
}
.button.button-text-only {
  border: 1px solid transparent;
}
.button .material-icons {
  margin-right: 10px;
  font-size: 16px;
}
.right .button {
    padding: 4px 8px;
    margin-bottom: 0;
}
.button-solid {
  background-color: #c5c5c5;
  color: black;
  transition: all 0.1s linear;
}
.button-alert {
  background-color: rgb(189, 131, 24);
}
.button-disabled {
  background-color: purple;
  pointer-events: none;
}
.cursor-pointer {
  cursor: pointer;
}
.button-group .button:last-child {
    margin-bottom: 0;
}
.button.disabled {
  pointer-events: none;
  opacity: 0.4;
}
a {
  color: white;
  text-decoration: none;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
  transition: all 0.1s linear;
}
a.text-light {
  border-bottom: 1px solid #adadad;
}
.button-group a {
  border-bottom: none;
}
.line-clamp-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9;
}
.menu-header .button {
  max-width: 100px;
  margin-bottom: 0;
}
.menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 27px;
    cursor: pointer;
}
.blur {
  filter: blur(10px);
}
.blur-bg {
    background-color: rgba(0, 0, 0, 0.199);
    backdrop-filter: blur(6px);
}
.top-bar {
    padding: 14px 20px 20px 20px;
    color: #898989;
    font-family: "Roboto Mono", monospace;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    font-weight: 700;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 999;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
}
.top-bar a {
    border: none;
}
.top-bar .right {
    display: flex;
    align-items: center;
}
.top-bar button {
    background-color: black;
    color: white;
    border: none;
    padding: 0;
}
button {
  cursor: pointer;
}
.slide-fade-enter-active, .slide-down-fade-enter-active, .slide-left-fade-enter-active {
  transition: all 0.6s cubic-bezier(.32,.69,.44,1);
}

.slide-fade-leave-active, .slide-down-fade-leave-active, .slide-left-fade-leave-active {
  transition: all 0.3s cubic-bezier(.32,.69,.44,1);
}

.slide-down-fade-move, .slide-down-fade-enter-active, .slide-down-fade-leave-active {
  position: absolute;
}

.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 1;
}

.slide-fade-leave-to {
  transform: translateY(20px) scale(90%);
  opacity: 0;
}

.slide-down-fade-enter-from {
  transform: translateY(-20px);
  opacity: 1;
}

.slide-down-fade-leave-to {
  transform: scale(90%);
  opacity: 0;
}

.slide-left-fade-enter-from {
  transform: translateX(20px);
  opacity: 1;
}

.slide-left-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.slide-enter-active {
  transition: all 0.6s cubic-bezier(.32,.69,.44,1);
}

.slide-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-enter-from, .slide-leave-to {
  transform: translateY(60px);
  opacity: 0;
}

.fade-move, .fade-enter-active {
  transition: all 0.5s cubic-bezier(.32,.69,.44,1);
}

.fade-move, .fade-leave-active {
  transition: all 0.5s cubic-bezier(.32,.69,.44,1);
  position: absolute;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-rotate-move, .fade-rotate-enter-active, .fade-rotate-leave-active {
  transition: all 1s cubic-bezier(.32,.69,.44,1);
  position: absolute;
}

.fade-rotate-enter-from,
.fade-rotate-leave-to {
  transform: rotateY(360deg);
}

.pulse {
    animation: pulse 2s cubic-bezier(.32,.69,.44,1) infinite;
}

.pulseSlow {
    animation: pulse 6s cubic-bezier(.32,.69,.44,1) infinite;
}
.onboard-card {
    width: 100%;
    box-sizing: border-box;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: fit-content;
}
.onboard-card img {
  width: 100%;
  margin-bottom: 20px;
}
.onboard-card .button-group {
  margin-bottom: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
.onboard-card .onboard-copy {
  margin-bottom: 50px;
}
.onboard-image .image-citation {
  position: absolute;
  bottom: 10px;
  left: 0;
  font-size: 10px;
  color: #444444;
  padding: 0 8px;
  width: 100vw;
  box-sizing: border-box;
}
.image-citation p {
  margin: 4px 0;
}
.onboard-image img {
  width: 100%;
}
.onboard-header {
  font-weight: 700;
  font-size: 18px;
}
.pip-display {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  z-index: 999;
  position: absolute;
  right: 10px;
  bottom: 75px;
  width: 80px;
  height: 80px;
}
.pip-display img {
  width: 100%;
}
.inset-container {
    padding: 10px;
    background-color: #141414;
    border-radius: 4px;
    padding-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
ul.unstyled {
    padding-left: 0;
    line-height: 22px;
}
li.line-item {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.line-item .dots {
    height: 1px;
    border-bottom: 1px dashed #404040;
    flex-grow: 2;
    margin: 0 6px;
}
.material-icons.small {
    font-size: inherit;
}
@keyframes loading {
  0% {opacity: 0.6;}
  50% {opacity: 1;}
  100% {opacity: 0.6;}
}
.loading {
    animation: loading 1s linear infinite;
}
.fadeIn {
    animation: fadeIn 1s cubic-bezier(.32,.69,.44,1) once;
}
@keyframes pulse {
  0% {opacity: 0.1;}
  50% {opacity: 0.6;}
  100% {opacity: 0.1;}
}
@keyframes fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
input, .cta-button {
    margin-bottom: 20px;
    background-color: white;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 10px 16px;
    color: black;
  }
input[type=submit], .cta-button {
  cursor: pointer;
}
.cta-button-secondary {
  background-color: transparent;
  color: white;
}
.square-rotate-2 {
  --size: 24px;
  --accent-opacity: .25;
  --color: currentColor;
  --animation-timing-function: linear;
  --animation-duration: 2s;
  position: relative;
  width: var(--size);
  height: var(--size);
}

.square-rotate-2::before,
.square-rotate-2::after {
  content: '';
  position: absolute;
  transform: rotate(0deg);
  animation: var(--animation-timing-function) var(--animation-duration) infinite square-rotate-2-animation;
}

.square-rotate-2::before {
  inset: 0;
  background-color: var(--color);
  opacity: var(--accent-opacity);
}

.square-rotate-2::after {
  top: 12.5%;
  left: 12.5%;
  width: 75%;
  height: 75%;
  background-color: var(--color);
  animation-direction: reverse;
}
@media screen and (min-width: 900px) {
  .login {
    width: 500px;
    height: 500px;
    margin: 0 auto;
    background-color: #e4002b;
    font-weight: bold;
    border-radius: 6px;
  }

  .pixelated {
    image-rendering: pixelated;
  }

  .custom-scrollbar::-webkit-scrollbar {
    width: 0px;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb { /* Foreground */
    background: rgb(22, 22, 22);
    border-radius: 4px;
  }
  .custom-scrollbar::-webkit-scrollbar-track { /* Background */
    background: #000;
  }
  .onboard-card {
    width: 400px;
    padding: 0;
    margin: auto;
  }
  .onboard-card .onboard-image {
    width: 240px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .onboard-card .button-group {
    margin: 0;
    width: 520px;
  }
  .button:hover, .button-solid:hover {
    opacity: 1;
  }
  .button-solid, .button {
    opacity: 0.6;
  }
}
</style>
<style>

</style>
