<template>
    <div class="inbox custom-scrollbars">
        Sharing coming soon...
    </div>
</template>

<script>
export default {
    name: 'Inbox',
    components: {
    },
    props: {
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>

<style scoped>
.inbox {
    background-color: black;
    grid-area: 1 / 1 / 4 / 1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    padding: 20px;
}
</style>